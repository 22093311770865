import { useEffect, useState } from 'react';
import { Alert, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import styled from 'styled-components';
import { MultiAnswerTask, MultiUserSelectTask, SingleAnswerTask, SingleUserSelectTask, TableAnswerTask } from '../../components';
import { useHistory } from "react-router-dom";
import { changeLocation, getAdminsForCurrentLocation, getEquipment, loadAdmin, loadUser, updateUser } from '../../actions';

const TaskContainer = styled.div`
    padding: 0rem 2rem;
    .label {
        font-weight: 600;
    }
`;

export const TableContainer = styled.div`
    overflow-y: auto;
    min-height: 10vh;
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : '61vh')};
`;

export const TableHead = styled.thead`
    background: white;
    top: 0;
    position: sticky;
    th {
          vertical-align: middle !important;
    }
`;

const LibraryTaskDetails = () => {
    const loginUser = useSelector(state => state.user.currentUser);
    const history = useHistory();
    const dispatch = useDispatch();

    const searchParams = new URLSearchParams(history.location.search);
    const [locationId, setLocationId] = useState(searchParams.get('locationId'))

    const [taskList, setTaskList] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [didCompleteLibrarySetup, setDidCompleteLibrarySetup] = useState(false);

    useEffect(() => {
        getMasterData();
    }, []);

    const getMasterData = async () => {
        try {
            setLoading(true);

            const { data: { payload: users } } = await axios.get(`/api/v2/master/users_by_location/${locationId}`);
            const { data: { payload: location_data } } = await axios.get(`/api/v2/libraryStepsWhichHaveTask/${locationId}`);
            setUsers(users);
            setTaskList(location_data.libraryData);
            setDidCompleteLibrarySetup(location_data.didCompleteLibrarySetup);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const customCodeHandler = (val) => {
        if (val && val.answer) {
            switch (val.type) {
                case 'singleInput':
                    return <SingleAnswerTask locationId={locationId} answerData={val} user={loginUser} getLibData={getMasterData} usersList={users} />
                    break;
                case 'table':
                    const headers = [...val.tableLayout.columns];

                    // !headers.includes("Frequency") && headers.splice(1, 0, "Frequency");
                    if (!headers.includes('Nickname')) headers.push("Nickname");
                    return <TableAnswerTask locationId={locationId} answerData={val} headers={headers} user={loginUser} getLibData={getMasterData} usersList={[{ value: 'shared', label: "Shared" }, ...users]} />
                    break;
                case 'multipleInput':
                    return <MultiAnswerTask locationId={locationId} answerData={val} user={loginUser} getLibData={getMasterData} usersList={users} />
                    break;
                case 'multipleUserSelect':
                    return <MultiUserSelectTask locationId={locationId} usersList={users} answerData={val} user={loginUser} getLibData={getMasterData} />
                    break;
                case 'singleUserSelect':
                    return <SingleUserSelectTask locationId={locationId} usersList={users} answerData={val} user={loginUser} getLibData={getMasterData} />
                    break;
                default:
                    break;
            };
        };
    };

    const changeLocationAndNavigateToLibrary = async () => {
        try {
            const { practice } = loginUser;
            setLoading(true);
            await dispatch(getEquipment(locationId));
            await dispatch(getAdminsForCurrentLocation());
            // store the location and practice indexes in the db
            await dispatch(updateUser({ currentLocationIndex: loginUser.locationIds.indexOf(locationId), currentPracticeIndex: loginUser.practiceIds.indexOf(practice._id) }, false));
            // now retrieve the updated user
            const updatedUser = await dispatch(loadUser(loginUser));
            if (updatedUser) {
                await dispatch(loadAdmin());
                history.push('/library');
            };
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("🚀 ~ changeLocation ~ error:", error)
        }
    };

    return (
        <TaskContainer>
            {loading ?
                <Col lg={12} style={{ minHeight: '30px' }} className='text-center'>
                    <Spinner animation="border" variant="success" />
                </Col>
                :
                <>
                    {
                        !didCompleteLibrarySetup ?
                            <>
                                <div>
                                    <Alert id='error-alert' className='my-4' style={{ 'maxWidth': '98%' }} variant="info">
                                        <h3 className='w-100 my-2 text-center'>
                                            Complete <a href='javascript:void(0)' onClick={() => changeLocationAndNavigateToLibrary()}>Library Set Up </a> in order for your Compliance Tasks to be generated.
                                        </h3>
                                    </Alert>
                                </div>
                            </>
                            :
                            taskList.length ? taskList.map((task) => (customCodeHandler(task))) :
                                <>
                                    <div>
                                        <Alert id='error-alert' className='my-4' style={{ 'maxWidth': '98%' }} variant="info">
                                            <h3 className='w-100 my-2 text-center'>
                                                Don't have a task based on the library setup
                                            </h3>
                                        </Alert>
                                    </div>
                                </>
                    }
                </>
            }
        </TaskContainer>
    );
};

export default LibraryTaskDetails;
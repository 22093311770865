import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ButtonGroup, Col, Row, Spinner, ToggleButton } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import styled from 'styled-components';

import libraryIcon from '../../assets/images/sidebar-library-normal.svg'
import LibraryView from './LibraryView';
import { loadUser } from '../../actions';
import { Button } from '../../components';

const AdditionalStepsContainer = styled.div`
  background: #ffffff;
  border: 1px solid #d3d3d3;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: 15px;

  @media (max-width:480px) {
     width:100%;
     height:100%;
     overflow-x: auto;
  }
  @media only screen and (max-width:1024px) and (min-width: 481px){
      width:100%;
      height:100%;
      overflow-x: auto;
  }
`;

const HeadersContainer = styled.div`
    font-weight:700 !important;
    color:black;
    display:flex;
    margin-bottom:1rem;
`;

const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
`;

const LibraryContainer = styled.div`
  width: 100%;
  height:100vh;
  padding: 15px;
  z-index:0;
  margin: 0 0rem 0 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
class Library extends Component {
  state = {
    options: [],
    notSelectedOptions: [],
    loading: false,
    defaultOptions: [],
  };

  componentDidMount() {
    const { dispatch, user } = this.props;
    dispatch(loadUser(user));
    this.getQuestions();
  };

  getQuestions = async () => {
    try {
      this.setState({ loading: true });
      const { data: { payload: defaultOptions } } = await axios.get('/api/v2/master/defaultoptions');
      this.setState({ defaultOptions, loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  onOptionChange = ({ name, value }) => {
    const { notSelectedOptions, options } = this.state;
    if (name === 'onSiteLab') {
      if (value === 'no') {
        const labIsCliaWaived = options.indexOf('labIsCliaWaived');
        if (labIsCliaWaived !== -1) {
          options.splice(labIsCliaWaived, 1);
        };
        document.getElementById('labIsCliaWaived').classList.add('d-none');
        if (!notSelectedOptions.includes('labIsCliaWaived')) notSelectedOptions.push('labIsCliaWaived');
      } else {
        document.getElementById('labIsCliaWaived').classList.remove('d-none');
      };
    };

    if (name === 'onSiteTbTesting') {
      if (value === 'no') {
        const onSiteTbTreatment = options.indexOf('onSiteTbTreatment');
        if (onSiteTbTreatment !== -1) {
          options.splice(onSiteTbTreatment, 1);
        };
        document.getElementById('onSiteTbTreatment').classList.add('d-none');
        if (!notSelectedOptions.includes('onSiteTbTreatment')) notSelectedOptions.push('onSiteTbTreatment');
      } else {
        document.getElementById('onSiteTbTreatment').classList.remove('d-none');
      };
    };

    const index1 = notSelectedOptions.indexOf(name);
    if (index1 !== -1) {
      notSelectedOptions.splice(index1, 1);
    };
    const index = options.indexOf(name);
    if (index !== -1) {
      options.splice(index, 1);
    };
    if (value === 'no') {
      notSelectedOptions.push(name);
    } else {
      options.push(name);
    };
    this.setState({ options, notSelectedOptions });
  };

  checkOptionAndHideOrShow = (id) => {
    const { options } = this.state;
    let result = false;
    if (['labIsCliaWaived', 'onSiteTbTreatment'].includes(id)) {
      if (!location) {
        return true;
      } else {
        if ('labIsCliaWaived' === id && !options.includes('onSiteLab')) result = true;
        if ('onSiteTbTreatment' === id && !options.includes('onSiteTbTesting')) result = true;
      }
    };
    return result;
  };

  onSave = async () => {
    try {
      const { dispatch, user, user: { location }, } = this.props;
      const { options, notSelectedOptions, defaultOptions } = this.state;
      if ([...options, ...notSelectedOptions].length !== defaultOptions.length) {
        return toast.error("Fill all required fields.");
      };
      await axios.put(`/api/v2/location/${location._id}`, { locationDetails: { options, setUpPending: false } });
      Swal.fire({
        icon: 'success',
        title: 'Location setup has been completed',
        allowEnterKey: true,
        allowEscapeKey: false,
        confirmButtonColor: '#4FB973',
        confirmButtonText: 'Close',
        allowOutsideClick: true
      }).then(() => {
        dispatch(loadUser(user));
      });
    } catch (error) {
      console.log("🚀 ~ Library ~ error:", error);
    }
  };

  render() {
    const { user: { location }, history, location: { pathname }, } = this.props;
    const { defaultOptions, options, notSelectedOptions, loading } = this.state;
    return (
      <LibraryContainer>
        {
          !location.additionalStepsAvailable && location.didCompleteLibrarySetup &&
          <LibraryView history={history} />
        }
        {
          location.setUpPending ?
            <div style={{ paddingTop: '3%' }}>
              <AdditionalStepsContainer className='p-5'>
                <h2>Location setup</h2>
                <p> Before starting library setup you need to complete the location setup.</p>
                {loading ?
                  <div className='w-100 text-center'>
                    <Spinner animation="border" variant="success" />
                  </div>
                  :
                  <div className='mx-2'>
                    {defaultOptions.length && defaultOptions.map((option) => (
                      <Row id={option.value} className={'mt-2 ' + (this.checkOptionAndHideOrShow(option.value) ? 'd-none' : '')} key={option.value}>
                        <Col>
                          <StyledLabel>{option.label} <span className="text-danger">*</span></StyledLabel>
                        </Col>
                        <Col>
                          <ButtonGroup toggle>
                            <ToggleButton
                              type="checkbox"
                              variant="outline-success"
                              name={option.value}
                              value='yes'
                              checked={options.includes(option.value)}
                              onChange={(e) => this.onOptionChange(e.currentTarget)}
                            >
                              Yes
                            </ToggleButton>
                          </ButtonGroup>
                          <ButtonGroup toggle className='ml-3' >
                            <ToggleButton
                              type="checkbox"
                              variant="outline-danger"
                              name={option.value}
                              value='no'
                              checked={notSelectedOptions.includes(option.value)}
                              onChange={(e) => this.onOptionChange(e.currentTarget)}
                            >
                              No
                            </ToggleButton>
                          </ButtonGroup>
                        </Col>
                      </Row>
                    ))}
                  </div>}
                <div className='d-flex justify-content-center mt-5'>
                  <Button className="w-auto m-0" onClick={this.onSave} variant='success' value="button">
                    Save and continue
                  </Button>
                </div>
              </AdditionalStepsContainer>
            </div>
            :
            !location.didCompleteLibrarySetup &&
            <div>
              <AdditionalStepsContainer>
                <HeadersContainer>
                  <img src={libraryIcon} alt="Icon" className='mr-3' />
                  <h2>Library Setup</h2>
                </HeadersContainer>
                <div>
                  <p>
                    The Library contains useful reference information about OSHA compliance, tailored to your practice. Each practice location has its own library.
                  </p>
                  <p>Enter some information about the {location.name} location to set up its Library.</p>
                  <div className='d-flex justify-content-center'>
                    <Button className="w-auto m-0" onClick={() => { history.push('/library/setup') }} type='button'>
                      {location?.lastCompletedLibrarySetupStep ? 'Continue Setup' : 'Get Started'}
                    </Button>
                  </div>
                </div>
              </AdditionalStepsContainer>
            </div>
        }
        {
          (location.didCompleteLibrarySetup && location.additionalStepsAvailable && pathname === '/library') &&
          <div>
            <AdditionalStepsContainer >
              <HeadersContainer>
                <img src={libraryIcon} alt="Icon" className='mr-3' />
                <h2>Library Updates</h2>
              </HeadersContainer>
              <div>
                <p>
                  Updates have been made to your library that require response. These updates can be based on new OSHA regulations as well as changes to the Ocoord platform. After these updates are completed for {location.name} your complete library and tasks will reappear.
                </p>
                {/* <p>Enter some information about the {location.name} location to set up its Library.</p> */}
                <div className='d-flex justify-content-center'>
                  <Button className="w-auto m-0" onClick={() => { history.push('/library/additionalLibrarySetup') }} type='button'>
                    Get Started
                  </Button>
                </div>
              </div>
            </AdditionalStepsContainer>
          </div>
        }
      </LibraryContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.currentUser
});

export default connect(mapStateToProps)(Library);

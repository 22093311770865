import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
import { CircularProgressbar } from "react-circular-progressbar";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styled from "styled-components";

import { CardBody, CardHeading, CardSubText, InfoCard, TrainingProgressContainer } from "./Style";
import libraryIcon from '../../assets/images/sidebar-library-selected.svg';
import recordsIcon from '../../assets/images/sidebar-records-selected.svg';

const CarousalContainer = styled.div`
    button[class^="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"]{
        z-index:99;
    }
    button[class^="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"]{
        z-index:99;
    }
`;

const DynamicNotification = () => {
    const currentUser = useSelector(state => state.user.currentUser);
    const [loading, setLoading] = useState(false);
    const [trainingData, setTrainingData] = useState({ trainingMinutesWatched: 0, trainingPercentage: 0, trainingDeadline: '' });

    const history = useHistory();

    useEffect(() => {
        getTrainingVideos();
    }, []);

    const getTrainingVideos = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get('/api/v2/training/' + currentUser._id);

            let trainingMinutesWatched = payload.trainingMinutesWatched || 0;
            trainingMinutesWatched = trainingMinutesWatched / 60;
            let trainingPercentage = ((trainingMinutesWatched / 60) * 100).toFixed(2);
            trainingPercentage = trainingPercentage > 100 ? 100 : trainingPercentage;
            setTrainingData({ trainingMinutesWatched, trainingPercentage, trainingDeadline: moment(payload.trainingMinutesResetAt ? payload.trainingMinutesResetAt : payload.createdAt).add(1, 'year').format('MMMM D, YYYY') });
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ file: TrainingList.jsx:62 ~ getTrainingVideos ~ error:", error);
            setLoading(false);
            // setError('Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.');
        }
    };

    return (
        <CarousalContainer>
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlay={true}
                autoPlaySpeed={3000}
                centerMode={false}
                draggable
                focusOnSelect={false}
                infinite={true}
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover={true}
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 3,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                    }
                }}
                rewind={true}
                rewindWithAnimation={true}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                slidesToSlide={1}
                swipeable
            >
                {!currentUser.hasDoneTutorial &&
                    <InfoCard onClick={() => history.push('/training')}>
                        <TrainingProgressContainer>
                            <CircularProgressbar
                                value={trainingData.trainingPercentage} text={trainingData.trainingPercentage + '%'} styles={{
                                    path: { stroke: `#4fb973`, strokeLinecap: 'butt' },
                                    text: { fill: '#4fb973', fontSize: '20px', fontWeight: 'bold' }
                                }}
                            />
                        </TrainingProgressContainer>
                        <CardBody>
                            <CardHeading>
                                {trainingData.trainingMinutesWatched > 60 ? 60 : trainingData.trainingMinutesWatched.toFixed(2)} Min <span>/ 60</span>
                            </CardHeading>
                            <CardSubText>You must watch at least 60 minutes of training by {trainingData.trainingDeadline}</CardSubText>
                        </CardBody>
                    </InfoCard>
                }
                {currentUser.location.additionalStepsAvailable &&
                    <InfoCard onClick={() => history.push('/library')}>
                        <TrainingProgressContainer>
                            <img src={libraryIcon} alt="" />
                        </TrainingProgressContainer>
                        <CardBody>
                            <CardHeading>
                                {/* Additional Library Setup */}
                                Library Updates
                            </CardHeading>
                            {/* <CardSubText>The Library contains useful reference information about OSHA compliance, tailored to each of your practice’s locations. Enter some information about each of your practice locations to set up the Library so other staff members can access it.
                            </CardSubText> */}
                             <CardSubText>
                             Updates have been made to your library that require response. These updates can be based on new OSHA regulations as well as changes to the Ocoord platform. After these updates are completed, your complete library and tasks will reappear.
                             </CardSubText>
                        </CardBody>
                    </InfoCard>
                }
                {!currentUser.didUploadHealthRecords &&
                    <InfoCard onClick={() => history.push('/forms/view?formId=uploadHealthDocumentForm')}>
                        <TrainingProgressContainer>
                            <img src={recordsIcon} alt="" />
                        </TrainingProgressContainer>
                        <CardBody>
                            <CardHeading>Health Documents</CardHeading>
                            <CardSubText>Upload a copy of your up-to-date immunization records. These records are kept private, only visible to your OSHA administrators.</CardSubText>
                        </CardBody>
                    </InfoCard>
                }
                {!currentUser.location.didCompleteLibrarySetup &&
                    <InfoCard onClick={() => history.push('/library')}>
                        <TrainingProgressContainer>
                            <img src={libraryIcon} alt="" />
                        </TrainingProgressContainer>
                        <CardBody>
                            <CardHeading>
                                Library Setup
                            </CardHeading>
                            <CardSubText>The Library contains useful reference information about OSHA compliance, tailored to each of your practice’s locations. Enter some information about each of your practice locations to set up the Library so other staff members can access it.
                            </CardSubText>
                        </CardBody>
                    </InfoCard>
                }
            </Carousel>
        </CarousalContainer>
    )
}

export default DynamicNotification;
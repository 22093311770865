import moment from "moment";

export const generateCertificate = (data, location_name) => {
    const docDefinition = {
        pageOrientation: 'landscape',
        pageMargins: [20, 20, 20, 20],
        pageSize: 'A4',
        info: {
            title: 'Certificate of Completion',
            author: 'Ocoord'
        },
        content: [
            {
                svg: `<svg width="148" height="37" viewBox="0 0 148 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M38.1553 14.4448L37.3791 13.7168L32.3075 18.4904L20.2338 29.8346L12.9353 23.0049L7.86371 27.7785L15.9383 35.3362C17.0814 36.4035 18.6294 37.0018 20.2424 36.9999C21.8554 36.998 23.4017 36.396 24.5419 35.3259L38.1753 22.519C39.3138 21.4456 39.9512 19.9921 39.9475 18.478C39.9437 16.964 39.2991 15.5133 38.1553 14.4448Z"
                fill="#4FB973" />
            <path
                d="M8.13194 18.5087L20.2075 7.15938L30.2726 16.5702L35.3442 11.7966L24.5011 1.66802C23.3595 0.599867 21.8128 0 20.2002 0C18.5877 0 17.041 0.599867 15.8993 1.66802L2.27866 14.4801C1.13965 15.551 0.5 17.002 0.5 18.5146C0.5 20.0273 1.13965 21.4782 2.27866 22.5492L5.81608 25.8583L10.8877 21.0847L8.13194 18.5087Z"
                fill="#3C3C3C" />
            <path
                d="M56.2506 10.7373C59.067 10.7373 61.0557 11.3278 62.2166 12.5087C63.3776 13.6896 63.9593 15.6899 63.9617 18.5098C63.9617 21.3319 63.38 23.3271 62.2166 24.4955C61.0532 25.6639 59.0646 26.2493 56.2506 26.2516C53.4125 26.2516 51.4074 25.6662 50.2356 24.4955C49.0637 23.3249 48.4772 21.3296 48.476 18.5098C48.476 15.6899 49.0625 13.6896 50.2356 12.5087C51.4086 11.3278 53.4137 10.7373 56.2506 10.7373ZM56.2506 13.6492C54.7964 13.6492 53.8154 13.9839 53.3076 14.6534C52.7999 15.3228 52.5436 16.6083 52.5387 18.5098C52.5387 20.3908 52.795 21.666 53.3076 22.3355C53.8203 23.0049 54.8013 23.3402 56.2506 23.3414C57.6576 23.3414 58.6228 23.0061 59.1464 22.3355C59.6699 21.6649 59.9311 20.3897 59.9298 18.5098C59.9298 16.6094 59.6687 15.3239 59.1464 14.6534C58.6241 13.9828 57.6588 13.6481 56.2506 13.6492Z"
                fill="#3C3C3C" />
            <path
                d="M79.9611 22.9215L80.3138 25.3236C78.7553 25.9203 76.8351 26.2187 74.5532 26.2187C71.7599 26.2187 69.787 25.6384 68.6345 24.478C67.482 23.3175 66.9064 21.3172 66.9076 18.4769C66.9076 15.6354 67.4887 13.6345 68.6509 12.4741C69.813 11.3136 71.7914 10.7334 74.5859 10.7334C76.7818 10.7334 78.6057 11.0136 80.0575 11.5739L79.6085 13.855C78.5639 13.8152 77.1139 13.7953 75.2585 13.7953C73.637 13.7953 72.5173 14.1255 71.8992 14.7859C71.2812 15.4462 70.9716 16.6766 70.9704 18.4769C70.9704 20.2954 71.28 21.5405 71.8992 22.2122C72.5185 22.884 73.6383 23.2187 75.2585 23.2164C76.8317 23.2273 78.4035 23.1287 79.9611 22.9215Z"
                fill="#3C3C3C" />
            <path
                d="M90.3592 10.7373C93.1755 10.7373 95.1648 11.3278 96.327 12.5087C97.4891 13.6896 98.0702 15.6899 98.0702 18.5098C98.0702 21.3319 97.4891 23.3271 96.327 24.4955C95.1648 25.6639 93.1755 26.2493 90.3592 26.2516C87.5222 26.2516 85.5172 25.6662 84.3441 24.4955C83.171 23.3249 82.5845 21.3296 82.5845 18.5098C82.5845 15.6899 83.171 13.6896 84.3441 12.5087C85.5172 11.3278 87.5222 10.7373 90.3592 10.7373ZM90.3592 13.6492C88.9049 13.6492 87.9239 13.9839 87.4162 14.6534C86.9084 15.3228 86.6521 16.6083 86.6472 18.5098C86.6472 20.3908 86.9035 21.666 87.4162 22.3355C87.9288 23.0049 88.9098 23.3402 90.3592 23.3414C91.7685 23.3414 92.7338 23.0061 93.2549 22.3355C93.776 21.6649 94.0372 20.3897 94.0384 18.5098C94.0384 16.6094 93.7772 15.3239 93.2549 14.6534C92.7326 13.9828 91.7673 13.6481 90.3592 13.6492Z"
                fill="#3C3C3C" />
            <path
                d="M108.786 10.7373C111.603 10.7373 113.592 11.3278 114.754 12.5087C115.916 13.6896 116.497 15.6899 116.497 18.5098C116.497 21.3319 115.916 23.3271 114.754 24.4955C113.592 25.6639 111.603 26.2493 108.786 26.2516C105.948 26.2516 103.943 25.6662 102.771 24.4955C101.599 23.3249 101.013 21.3296 101.012 18.5098C101.012 15.6899 101.598 13.6896 102.771 12.5087C103.944 11.3278 105.949 10.7373 108.786 10.7373ZM108.786 13.6492C107.332 13.6492 106.351 13.9839 105.843 14.6534C105.336 15.3228 105.079 16.6083 105.074 18.5098C105.074 20.3908 105.331 21.666 105.843 22.3355C106.356 23.0049 107.337 23.3402 108.786 23.3414C110.196 23.3414 111.161 23.0061 111.682 22.3355C112.203 21.6649 112.464 20.3897 112.465 18.5098C112.465 16.6094 112.204 15.3239 111.682 14.6534C111.16 13.9828 110.194 13.6481 108.786 13.6492Z"
                fill="#3C3C3C" />
            <path
                d="M130.765 10.7373L130.349 14.159H129.229C128.12 14.159 126.392 14.5988 124.045 15.4785V25.8611H120.142V11.1584H123.181L123.534 13.3185C125.773 11.5977 127.928 10.7373 129.996 10.7373H130.765Z"
                fill="#3C3C3C" />
            <path
                d="M147.5 4.8584V25.8623H144.43L144.11 23.8523C142.292 25.4321 140.266 26.2226 138.031 26.2237C136.116 26.2203 134.719 25.5651 133.838 24.258C132.956 22.951 132.52 21.0052 132.527 18.4206C132.527 15.6007 133.012 13.6157 133.981 12.4655C134.951 11.3153 136.47 10.7396 138.54 10.7385C140.545 10.7385 142.283 11.1687 143.755 12.0291C143.638 11.0527 143.585 10.0706 143.595 9.08816V4.8584H147.5ZM143.597 21.6019V14.3102C141.891 13.9101 140.589 13.71 139.693 13.71C138.481 13.71 137.649 14.0152 137.197 14.6256C136.745 15.2359 136.521 16.4913 136.526 18.3916C136.526 20.251 136.745 21.5064 137.182 22.1576C137.62 22.8089 138.382 23.134 139.469 23.1328C140.115 23.1462 140.757 23.039 141.358 22.8174C142.14 22.4741 142.89 22.0674 143.597 21.6019Z"
                fill="#3C3C3C" />
        </svg>
        `,
                style: 'logo'
            },
            {
                text: 'Certificate of Completion',
                style: 'heading'
            },
            {
                text: 'This certificate acknowledges that',
                style: 'sub_text'
            },
            {
                text: data.fullName,
                style: 'user_name'
            },
            {
                canvas: [
                    {
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 595.28, // A4 width in points (approx. 595.28 points, 8.27 inches)
                        y2: 0,
                        lineWidth: 2,
                        lineColor: '#4FB973'
                    }
                ],
                style: 'underline'
            },
            {
                text: [
                    {
                        text: `has completed the required ${data.formId === "oshaAnnualTraining" ? "Annual OSHA training " : "New hire training "}at\n`,
                    },
                    {
                        text: location_name,
                        style: 'bold_text'
                    }, {
                        text: ' on '
                    },
                    {
                        text: moment(data.completedAt).format('MMM DD, YYYY'),//May 14, 2024.',
                        style: 'bold_text'
                    }
                ],
                style: 'body_text'
            }
        ],
        styles: {
            logo: {
                alignment: 'center',
                margin: [0, 15, 0, 15]
            },
            underline: {
                width: '100%',
                alignment: 'center'
            },
            heading: {
                fontSize: 50,
                bold: true,
                alignment: 'center'
            },
            sub_text: {
                fontSize: 22,
                alignment: 'center',
                margin: [0, 5, 0, 10]
            },
            user_name: {
                fontSize: 40,
                bold: true,
                alignment: 'center',
                margin: [0, 20, 0, 10]
            },
            body_text: {
                fontSize: 25,
                alignment: 'center',
                margin: [0, 20, 0, 0]
            },
            bold_text: {
                bold: true
            }
        },
        background: [
            {
                svg: `<svg width="368" height="433" viewBox="0 0 368 433" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="10.3027" cy="404.068" rx="481.523" ry="241.619" transform="rotate(50.7945 10.3027 404.068)"
                fill="#F3F9F5" />
        </svg>`,
                fit: [400, 400],
                absolutePosition: { x: 0, y: 200 },
                opacity: 0.3
            },
            {
                svg: `<svg width="79" height="142" viewBox="0 0 79 142" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M70.71 55.1414L67.6489 52.4141L47.9668 70.6914L1.11027 114.146L-27.6724 88.4996L-47.3545 106.777L-15.5107 135.151C-11.0027 139.158 -4.92755 141.373 1.38246 141.312C7.69247 141.25 13.7222 138.916 18.1492 134.821L71.0589 85.7849C73.2499 83.7489 74.979 81.341 76.1473 78.699C77.3157 76.0569 77.9003 73.2324 77.8679 70.3867C77.8355 67.5411 77.1867 64.73 75.9585 62.1141C74.7303 59.4983 72.9469 57.1289 70.71 55.1414Z"
                fill="url(#paint0_linear_2584_36922)" />
            <path
                d="M-46.6178 71.6143L0.245852 28.1595L39.9393 63.5049L59.6213 45.2276L16.8882 7.15471C12.3805 3.14883 6.30595 0.934511 -0.00288323 0.997508C-6.31172 1.0605 -12.3397 3.39563 -16.7646 7.49071L-69.6742 56.527C-74.0894 60.6307 -76.5367 66.1561 -76.4794 71.8912C-76.4221 77.6262 -73.8648 83.1026 -69.3685 87.1191L-55.418 99.5455L-35.736 81.2682L-46.6178 71.6143Z"
                fill="#232323" />
            <defs>
                <linearGradient id="paint0_linear_2584_36922" x1="14.9892" y1="52.8665" x2="15.7729" y2="141.133"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#69FF90" />
                    <stop offset="1" stop-color="#2E9968" />
                </linearGradient>
            </defs>
        </svg>
        `,
                fit: [150, 150],
                absolutePosition: { x: 0, y: 380 },
                opacity: 0.3
            },
            {
                svg: `<svg width="404" height="379" viewBox="0 0 404 379" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="522.037" cy="-37" rx="595.738" ry="298.929" transform="rotate(34.0077 522.037 -37)" fill="#F3F9F5" />
        </svg>
        `,
                fit: [400, 400],
                absolutePosition: { x: 430, y: 0 },
                opacity: 0.3
            },
            {
                svg: `<svg width="75" height="107" viewBox="0 0 75 107" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M112.479 41.8312L110.168 39.7351L95.0664 53.5121L59.1158 86.2668L37.3842 66.5559L22.283 80.3329L46.3258 102.14C49.7295 105.22 54.3379 106.947 59.1404 106.942C63.9428 106.937 68.5472 105.202 71.9437 102.115L112.538 65.1536C114.219 63.6189 115.552 61.7982 116.458 59.7954C117.365 57.7926 117.829 55.647 117.824 53.4812C117.818 51.3154 117.343 49.1718 116.426 47.1728C115.509 45.1739 114.168 43.3588 112.479 41.8312Z"
                fill="url(#paint0_linear_2584_36918)" />
            <path
                d="M23.08 53.5787L59.036 20.8239L89.0057 47.9892L104.107 34.2122L71.8423 4.95089C68.4388 1.87205 63.8309 0.14609 59.0293 0.151625C54.2278 0.157159 49.6247 1.8937 46.2298 4.98038L5.63515 41.942C2.24757 45.0353 0.347964 49.2238 0.353037 53.5887C0.358111 57.9535 2.26745 62.1383 5.66221 65.2251L16.1952 74.7756L31.2964 60.9986L23.08 53.5787Z"
                fill="#232323" />
            <defs>
                <linearGradient id="paint0_linear_2584_36918" x1="70.0898" y1="39.7254" x2="70.0929" y2="106.903"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#69FF90" />
                    <stop offset="1" stop-color="#2E9968" />
                </linearGradient>
            </defs>
        </svg>
        `,
                fit: [150, 150],
                absolutePosition: { x: 730, y: 50 },
                opacity: 0.3
            },
            {
                svg: `<svg width="135" height="135" viewBox="0 0 135 135" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="67.5" cy="67.5" r="67.5" fill="#42A764" />
            <path
                d="M126.001 71.1458C126.023 70.7633 125.976 70.4148 125.861 70.1004C125.749 69.7893 125.575 69.519 125.337 69.2895C125.103 69.0634 124.814 68.8833 124.469 68.7491C124.125 68.6149 123.733 68.5351 123.293 68.5096C122.712 68.4759 122.2 68.5367 121.76 68.692C121.323 68.8507 120.976 69.1035 120.721 69.4504C120.466 69.8005 120.323 70.2474 120.292 70.7913C120.274 71.1011 120.282 71.3935 120.317 71.6684C120.355 71.9466 120.406 72.2176 120.47 72.4814L119.768 72.4408C119.688 72.1823 119.633 71.9127 119.605 71.6319C119.574 71.3509 119.569 71.0191 119.592 70.6365C119.632 69.9314 119.812 69.3501 120.13 68.8927C120.452 68.4386 120.886 68.1084 121.435 67.9023C121.982 67.6992 122.62 67.6188 123.347 67.6609C123.872 67.6914 124.347 67.7918 124.772 67.9624C125.2 68.1363 125.563 68.3731 125.863 68.6728C126.163 68.9757 126.386 69.3376 126.533 69.7586C126.683 70.1829 126.743 70.6622 126.712 71.1966C126.691 71.5475 126.637 71.8838 126.548 72.2055C126.46 72.5271 126.343 72.8154 126.199 73.0704L125.534 72.7083C125.648 72.4928 125.749 72.2544 125.837 71.993C125.928 71.7349 125.983 71.4526 126.001 71.1458Z"
                fill="#FCFCFC" />
            <path
                d="M118.708 78.0459L119.36 74.234L126.206 75.4047L125.555 79.2165L124.852 79.0964L125.368 76.0806L123.153 75.7019L122.667 78.5444L121.974 78.4259L122.46 75.5834L119.926 75.1502L119.411 78.166L118.708 78.0459Z"
                fill="#FCFCFC" />
            <path
                d="M124.592 83.0434C124.437 83.5919 124.24 84.025 124.002 84.3427C123.766 84.6643 123.484 84.8742 123.157 84.9724C122.829 85.0706 122.451 85.0589 122.021 84.9372C121.668 84.837 121.39 84.6893 121.189 84.494C120.99 84.2996 120.847 84.078 120.76 83.8294C120.672 83.5807 120.622 83.3278 120.609 83.0707L117.087 84.0531L117.343 83.1481L120.595 82.3014L120.989 80.9118L118.2 80.122L118.421 79.345L125.103 81.2378L124.592 83.0434ZM123.933 82.8073L124.211 81.8246L121.647 81.0982L121.347 82.1587C121.192 82.7042 121.19 83.1364 121.34 83.4554C121.489 83.7774 121.78 83.9997 122.213 84.1223C122.667 84.2509 123.027 84.2048 123.294 83.9841C123.559 83.7664 123.772 83.3741 123.933 82.8073Z"
                fill="#FCFCFC" />
            <path
                d="M115.963 86.8492L116.265 86.0952L122.05 88.4156L122.864 86.3873L123.525 86.6526L121.599 91.4544L120.938 91.1891L121.748 89.1696L115.963 86.8492Z"
                fill="#FCFCFC" />
            <path d="M114.68 89.7377L120.905 92.8194L120.547 93.5432L114.322 90.4615L114.68 89.7377Z" fill="#FCFCFC" />
            <path
                d="M113.012 92.9269L113.425 92.233L119.392 95.7878L117.413 99.1101L116.801 98.7454L118.367 96.1169L116.179 94.8137L114.708 97.283L114.1 96.9207L115.571 94.4514L113.012 92.9269Z"
                fill="#FCFCFC" />
            <path d="M110.624 96.7096L116.3 100.713L115.834 101.373L110.158 97.3696L110.624 96.7096Z" fill="#FCFCFC" />
            <path
                d="M106.489 102L108.964 99.0286L114.301 103.474L111.826 106.445L111.278 105.989L113.236 103.638L111.51 102.2L109.664 104.416L109.124 103.966L110.969 101.75L108.994 100.105L107.036 102.456L106.489 102Z"
                fill="#FCFCFC" />
            <path
                d="M104.092 109.323C103.554 108.756 103.206 108.184 103.051 107.606C102.897 107.031 102.925 106.46 103.135 105.895C103.344 105.333 103.727 104.787 104.285 104.257L105.652 102.958L110.437 107.992L108.919 109.436C108.407 109.922 107.876 110.247 107.328 110.41C106.781 110.575 106.234 110.569 105.685 110.392C105.136 110.219 104.605 109.863 104.092 109.323ZM104.689 108.717C105.119 109.169 105.549 109.47 105.98 109.62C106.409 109.772 106.836 109.78 107.263 109.646C107.69 109.515 108.113 109.251 108.534 108.851L109.377 108.049L105.545 104.017L104.832 104.694C104.139 105.353 103.781 106.019 103.76 106.69C103.736 107.364 104.046 108.039 104.689 108.717Z"
                fill="#FCFCFC" />
            <path
                d="M92.9996 119.81C93.3437 119.641 93.6243 119.429 93.8416 119.174C94.0574 118.924 94.2069 118.639 94.2901 118.319C94.3718 118.004 94.3865 117.664 94.334 117.298C94.2816 116.932 94.1585 116.551 93.9646 116.156C93.708 115.633 93.4038 115.218 93.052 114.91C92.6988 114.607 92.3085 114.43 91.8811 114.379C91.4509 114.329 90.9913 114.424 90.5021 114.663C90.2235 114.8 89.973 114.951 89.7508 115.117C89.5271 115.286 89.3161 115.464 89.1178 115.649L88.8082 115.018C88.9938 114.821 89.202 114.641 89.4327 114.479C89.662 114.314 89.9487 114.146 90.2928 113.978C90.9269 113.667 91.5214 113.537 92.0763 113.59C92.6297 113.646 93.1309 113.863 93.5798 114.239C94.026 114.616 94.4094 115.132 94.7302 115.786C94.9617 116.258 95.1076 116.721 95.1679 117.175C95.2267 117.633 95.1993 118.066 95.0857 118.474C94.9692 118.884 94.7638 119.256 94.4694 119.591C94.1736 119.93 93.7854 120.218 93.3049 120.454C92.9892 120.608 92.6696 120.726 92.346 120.807C92.0225 120.888 91.7141 120.929 91.4209 120.928L91.4097 120.171C91.6534 120.165 91.9107 120.135 92.1816 120.084C92.4511 120.036 92.7238 119.945 92.9996 119.81Z"
                fill="#FCFCFC" />
            <path
                d="M83.4436 116.827L87.0843 115.523L89.4264 122.062L85.7857 123.366L85.5454 122.695L88.4257 121.664L87.668 119.548L84.9531 120.52L84.716 119.859L87.4309 118.886L86.5642 116.466L83.6839 117.498L83.4436 116.827Z"
                fill="#FCFCFC" />
            <path
                d="M82.0079 124.397C81.4539 124.531 80.98 124.573 80.5863 124.522C80.1902 124.474 79.8689 124.332 79.6225 124.096C79.376 123.859 79.2001 123.523 79.0948 123.09C79.0081 122.732 79.0003 122.418 79.0713 122.147C79.1431 121.878 79.2657 121.645 79.4392 121.446C79.6126 121.248 79.8081 121.08 80.0257 120.942L77.4383 118.358L78.3525 118.136L80.6887 120.552L82.0922 120.211L81.4088 117.395L82.1937 117.204L83.8315 123.954L82.0079 124.397ZM81.8894 123.707L82.882 123.466L82.2535 120.876L81.1824 121.136C80.6315 121.27 80.254 121.48 80.05 121.768C79.8428 122.056 79.7923 122.418 79.8984 122.855C80.0097 123.314 80.227 123.605 80.5503 123.729C80.8705 123.853 81.3169 123.846 81.8894 123.707Z"
                fill="#FCFCFC" />
            <path
                d="M74.4296 118.752L75.235 118.646L76.0492 124.826L78.2159 124.54L78.309 125.247L73.1796 125.923L73.0865 125.216L75.2438 124.932L74.4296 118.752Z"
                fill="#FCFCFC" />
            <path d="M71.2878 119.054L71.6629 125.99L70.8564 126.033L70.4814 119.098L71.2878 119.054Z" fill="#FCFCFC" />
            <path
                d="M67.4652 119.167L68.2726 119.186L68.1117 126.13L64.2456 126.04L64.2621 125.328L67.3208 125.399L67.3798 122.853L64.5063 122.786L64.5227 122.079L67.3962 122.145L67.4652 119.167Z"
                fill="#FCFCFC" />
            <path d="M62.9929 118.939L62.2977 125.85L61.4942 125.769L62.1893 118.858L62.9929 118.939Z" fill="#FCFCFC" />
            <path
                d="M56.4211 117.942L60.2252 118.638L58.9761 125.47L55.172 124.775L55.3001 124.074L58.3098 124.624L58.7139 122.414L55.8772 121.895L56.0036 121.203L58.8404 121.722L59.3026 119.194L56.2929 118.643L56.4211 117.942Z"
                fill="#FCFCFC" />
            <path
                d="M48.8774 119.443C49.1076 118.696 49.4363 118.112 49.8634 117.694C50.2896 117.278 50.8007 117.023 51.3966 116.928C51.9886 116.835 52.6523 116.902 53.3879 117.129L55.1904 117.684L53.1464 124.322L51.144 123.705C50.469 123.498 49.926 123.194 49.5151 122.796C49.1033 122.401 48.8403 121.921 48.7261 121.355C48.6079 120.792 48.6583 120.155 48.8774 119.443ZM49.6985 119.666C49.5149 120.263 49.4633 120.785 49.5438 121.234C49.6212 121.682 49.8233 122.059 50.1499 122.365C50.4726 122.673 50.9109 122.913 51.4648 123.083L52.5772 123.426L54.2144 118.109L53.2746 117.82C52.3604 117.538 51.6054 117.553 51.0094 117.863C50.4104 118.172 49.9735 118.773 49.6985 119.666Z"
                fill="#FCFCFC" />
            <path
                d="M34.3301 114.893C34.6457 115.11 34.9677 115.251 35.2962 115.316C35.6202 115.382 35.9419 115.372 36.2612 115.289C36.5761 115.206 36.8803 115.052 37.1736 114.827C37.467 114.602 37.7385 114.308 37.9883 113.946C38.319 113.466 38.5321 112.997 38.6279 112.54C38.7192 112.084 38.6827 111.657 38.5183 111.259C38.3514 110.859 38.0436 110.505 37.595 110.196C37.3394 110.02 37.0851 109.875 36.832 109.763C36.5746 109.651 36.3165 109.554 36.0577 109.471L36.4566 108.892C36.7192 108.958 36.9778 109.051 37.2324 109.173C37.4889 109.292 37.7749 109.46 38.0905 109.678C38.6721 110.078 39.0759 110.534 39.3018 111.043C39.5233 111.553 39.58 112.096 39.4719 112.672C39.3612 113.246 39.0992 113.832 38.6859 114.432C38.3876 114.865 38.0554 115.219 37.6892 115.494C37.3186 115.769 36.9275 115.957 36.5156 116.058C36.1012 116.157 35.6759 116.16 35.2399 116.068C34.7994 115.976 34.3587 115.778 33.918 115.474C33.6285 115.275 33.3692 115.054 33.1401 114.811C32.911 114.569 32.725 114.32 32.5822 114.063L33.2364 113.683C33.3614 113.893 33.5129 114.103 33.6906 114.314C33.8639 114.525 34.0771 114.718 34.3301 114.893Z"
                fill="#FCFCFC" />
            <path
                d="M32.2459 105.085L35.1626 107.624L30.6018 112.863L27.6851 110.323L28.153 109.786L30.4606 111.795L31.9361 110.1L29.7612 108.206L30.2228 107.676L32.3978 109.57L34.0855 107.631L31.7779 105.622L32.2459 105.085Z"
                fill="#FCFCFC" />
            <path
                d="M24.9831 107.586C24.5934 107.169 24.3239 106.777 24.1746 106.41C24.0208 106.042 23.9863 105.692 24.0711 105.361C24.1559 105.03 24.3612 104.712 24.6872 104.406C24.9553 104.155 25.225 103.994 25.4963 103.922C25.7652 103.852 26.0287 103.844 26.2869 103.897C26.5451 103.951 26.7876 104.038 27.0144 104.16L27.9902 100.636L28.6332 101.322L27.6808 104.545L28.6681 105.599L30.7832 103.618L31.3353 104.207L26.266 108.955L24.9831 107.586ZM25.5253 107.143L26.2236 107.888L28.1688 106.066L27.4153 105.262C27.0278 104.848 26.6587 104.623 26.3081 104.587C25.9553 104.549 25.6148 104.683 25.2865 104.991C24.9421 105.313 24.7956 105.646 24.8472 105.988C24.8966 106.328 25.1226 106.713 25.5253 107.143Z"
                fill="#FCFCFC" />
            <path
                d="M26.1679 98.2072L26.6577 98.8553L21.6849 102.613L23.0025 104.357L22.4339 104.786L19.3147 100.659L19.8832 100.229L21.1951 101.965L26.1679 98.2072Z"
                fill="#FCFCFC" />
            <path d="M24.3763 95.6448L18.5299 99.3948L18.0939 98.715L23.9403 94.9649L24.3763 95.6448Z" fill="#FCFCFC" />
            <path
                d="M22.4689 92.5123L22.8485 93.2252L16.7178 96.4899L14.9002 93.0765L15.5292 92.7415L16.9672 95.442L19.2149 94.2451L17.8639 91.7082L18.4887 91.3755L19.8397 93.9124L22.4689 92.5123Z"
                fill="#FCFCFC" />
            <path d="M20.4667 88.5158L14.1003 91.2929L13.7774 90.5527L20.1438 87.7755L20.4667 88.5158Z" fill="#FCFCFC" />
            <path
                d="M18.0958 82.2865L19.3586 85.9417L12.7937 88.2099L11.5308 84.5547L12.2044 84.322L13.2035 87.2138L15.3275 86.48L14.3858 83.7543L15.0503 83.5247L15.992 86.2504L18.4213 85.411L17.4222 82.5192L18.0958 82.2865Z"
                fill="#FCFCFC" />
            <path
                d="M13.0777 76.459C13.842 76.2919 14.5114 76.2915 15.0859 76.4577C15.6573 76.6246 16.1308 76.9442 16.5063 77.4165C16.8781 77.8864 17.1462 78.4972 17.3105 79.2491L17.7133 81.0917L10.9278 82.5749L10.4804 80.5281C10.3296 79.8381 10.3266 79.2163 10.4714 78.6627C10.6131 78.1097 10.9021 77.6445 11.3384 77.2671C11.7708 76.8873 12.3506 76.6179 13.0777 76.459ZM13.2872 77.2837C12.6776 77.4169 12.1971 77.6289 11.8457 77.9197C11.4936 78.2074 11.2646 78.5687 11.1587 79.0036C11.0489 79.4361 11.056 79.9354 11.1797 80.5017L11.4283 81.6388L16.8632 80.4508L16.6532 79.4901C16.449 78.5556 16.0653 77.9052 15.5021 77.5387C14.9383 77.1692 14.2 77.0842 13.2872 77.2837Z"
                fill="#FCFCFC" />
            <path
                d="M9.86121 61.5272C9.82726 61.9089 9.86293 62.2587 9.96822 62.5765C10.0706 62.8909 10.2369 63.1664 10.4671 63.4031C10.6943 63.6363 10.978 63.8253 11.3181 63.97C11.6582 64.1147 12.0475 64.2066 12.486 64.2456C13.0665 64.2972 13.5792 64.2522 14.0243 64.1105C14.4664 63.9654 14.8202 63.7234 15.0857 63.3846C15.3514 63.0425 15.5084 62.6002 15.5566 62.0576C15.5841 61.7484 15.5847 61.4559 15.5584 61.1801C15.5291 60.9009 15.4866 60.6284 15.4307 60.3627L16.1311 60.425C16.2033 60.6858 16.2491 60.957 16.2686 61.2385C16.2912 61.5203 16.2856 61.8521 16.2516 62.2339C16.1891 62.9374 15.9916 63.5128 15.6593 63.9602C15.3241 64.4042 14.8794 64.7208 14.3252 64.91C13.7713 65.096 13.1315 65.1568 12.4059 65.0922C11.8822 65.0457 11.4107 64.9306 10.9914 64.747C10.5693 64.5601 10.2129 64.3121 9.92241 64.0033C9.63218 63.6913 9.42013 63.3227 9.28624 62.8974C9.14948 62.4687 9.10481 61.9878 9.15223 61.4546C9.18337 61.1044 9.24809 60.77 9.34639 60.4512C9.4447 60.1324 9.57017 59.8479 9.7228 59.5975L10.3756 59.9799C10.255 60.1918 10.1467 60.427 10.0505 60.6855C9.95153 60.9406 9.88842 61.2212 9.86121 61.5272Z"
                fill="#FCFCFC" />
            <path
                d="M17.348 54.9178L16.5799 58.708L9.77256 57.3285L10.5406 53.5384L11.239 53.6799L10.6314 56.6785L12.8338 57.1248L13.4065 54.2985L14.0956 54.4381L13.5229 57.2645L16.0419 57.7749L16.6495 54.7763L17.348 54.9178Z"
                fill="#FCFCFC" />
            <path
                d="M11.5997 49.7884C11.7716 49.2448 11.9814 48.8179 12.229 48.5075C12.4745 48.1932 12.7624 47.9919 13.0926 47.9036C13.4228 47.8154 13.8008 47.8386 14.2266 47.9732C14.5769 48.084 14.8497 48.24 15.0452 48.4414C15.2376 48.6417 15.3738 48.8675 15.4538 49.1187C15.5339 49.3699 15.5764 49.6241 15.5814 49.8815L19.1318 49.0061L18.8482 49.903L15.5722 50.6509L15.1368 52.028L17.8999 52.9017L17.6564 53.6718L11.0339 51.5776L11.5997 49.7884ZM12.2513 50.0442L11.9433 51.0181L14.4845 51.8217L14.8168 50.7708C14.9877 50.2303 15.0031 49.7983 14.8629 49.4749C14.7236 49.1485 14.4395 48.9175 14.0107 48.7819C13.5608 48.6396 13.1992 48.6748 12.926 48.8874C12.6538 49.0969 12.4289 49.4826 12.2513 50.0442Z"
                fill="#FCFCFC" />
            <path
                d="M20.4075 46.0733L20.0799 46.8167L14.376 44.3032L13.4948 46.303L12.8426 46.0156L14.9289 41.2813L15.5811 41.5686L14.7036 43.5598L20.4075 46.0733Z"
                fill="#FCFCFC" />
            <path d="M21.7676 43.2502L15.6491 39.9629L16.0313 39.2514L22.1499 42.5387L21.7676 43.2502Z" fill="#FCFCFC" />
            <path
                d="M23.5107 40.154L23.0747 40.8338L17.2283 37.0837L19.3162 33.8286L19.9161 34.2134L18.2642 36.7887L20.4076 38.1635L21.9594 35.7442L22.5553 36.1264L21.0034 38.5457L23.5107 40.154Z"
                fill="#FCFCFC" />
            <path d="M25.9723 36.5007L20.4309 32.313L20.9179 31.6687L26.4592 35.8563L25.9723 36.5007Z" fill="#FCFCFC" />
            <path
                d="M30.1651 31.4446L27.6046 34.3427L22.3994 29.744L24.9598 26.8458L25.4939 27.3177L23.4682 29.6105L25.1522 31.0984L27.0616 28.9372L27.5885 29.4028L25.6792 31.5639L27.6053 33.2657L29.631 30.9728L30.1651 31.4446Z"
                fill="#FCFCFC" />
            <path
                d="M32.7677 24.1759C33.2899 24.7584 33.6204 25.3405 33.7591 25.9222C33.8957 26.5016 33.8512 27.0711 33.6255 27.6308C33.4001 28.1859 33.0009 28.7204 32.4278 29.2341L31.0235 30.4931L26.387 25.3215L27.947 23.9229C28.4729 23.4514 29.0123 23.1422 29.5654 22.9952C30.1163 22.8458 30.6635 22.8678 31.2069 23.0612C31.7506 23.2501 32.2709 23.6217 32.7677 24.1759ZM32.1536 24.7648C31.7371 24.3002 31.3157 23.9867 30.8894 23.8244C30.4655 23.66 30.0383 23.6389 29.6077 23.7612C29.1773 23.879 28.7464 24.1314 28.3148 24.5183L27.4482 25.2953L31.1618 29.4376L31.894 28.7811C32.6062 28.1426 32.9829 27.488 33.024 26.8174C33.0674 26.1447 32.7773 25.4604 32.1536 24.7648Z"
                fill="#FCFCFC" />
            <path
                d="M42.0866 14.9474C41.7444 15.1199 41.4661 15.3348 41.2517 15.5919C41.0387 15.8448 40.8923 16.1314 40.8127 16.4518C40.7345 16.7679 40.7236 17.1086 40.7801 17.4739C40.8366 17.8391 40.964 18.2183 41.1623 18.6114C41.4247 19.1317 41.7335 19.5435 42.0886 19.8468C42.4452 20.1459 42.8374 20.3187 43.2654 20.3654C43.6961 20.4107 44.1547 20.3106 44.6411 20.0653C44.9182 19.9256 45.167 19.7717 45.3874 19.6038C45.6092 19.4317 45.8182 19.2518 46.0144 19.0641L46.331 19.6919C46.1476 19.8909 45.9414 20.0729 45.7125 20.238C45.4851 20.4059 45.2002 20.5761 44.8581 20.7487C44.2274 21.0668 43.6344 21.2027 43.079 21.1564C42.525 21.1059 42.0214 20.8952 41.5683 20.5243C41.118 20.1519 40.7289 19.6405 40.4008 18.9901C40.1641 18.5206 40.013 18.0594 39.9477 17.6063C39.8838 17.1491 39.9064 16.7155 40.0155 16.3058C40.1274 15.8947 40.3286 15.52 40.6192 15.1819C40.9112 14.8395 41.2962 14.5478 41.7741 14.3068C42.088 14.1485 42.4063 14.027 42.729 13.9423C43.0516 13.8576 43.3596 13.8141 43.6527 13.8116L43.6724 14.5679C43.4288 14.5773 43.1718 14.6093 42.9014 14.6641C42.6325 14.7146 42.3609 14.809 42.0866 14.9474Z"
                fill="#FCFCFC" />
            <path
                d="M51.3175 17.9417L47.6969 19.3003L45.2568 12.7973L48.8775 11.4387L49.1278 12.1059L46.2633 13.1807L47.0527 15.2846L49.7527 14.2716L49.9997 14.9299L47.2997 15.943L48.2027 18.3493L51.0672 17.2745L51.3175 17.9417Z"
                fill="#FCFCFC" />
            <path
                d="M52.9677 10.2407C53.5201 10.0999 53.9935 10.0529 54.3878 10.0995C54.7844 10.1423 55.1072 10.2807 55.3564 10.5146C55.6056 10.7486 55.7853 11.0819 55.8955 11.5147C55.9862 11.8707 55.9976 12.1848 55.9297 12.4571C55.861 12.7262 55.7411 12.9611 55.5699 13.1616C55.3987 13.362 55.2051 13.5323 54.9891 13.6723L57.6058 16.2267L56.6943 16.4589L54.3307 14.0705L52.9311 14.427L53.6465 17.2353L52.8639 17.4347L51.1492 10.704L52.9677 10.2407ZM53.0941 10.9292L52.1042 11.1813L52.7622 13.7641L53.8303 13.492C54.3797 13.352 54.7547 13.1372 54.9555 12.8474C55.1593 12.5569 55.2057 12.1937 55.0946 11.7579C54.9781 11.3006 54.7575 11.012 54.4328 10.8921C54.1112 10.7714 53.6649 10.7837 53.0941 10.9292Z"
                fill="#FCFCFC" />
            <path
                d="M60.2946 15.8334L59.4909 15.9519L58.5819 9.7854L56.4199 10.1041L56.316 9.39909L61.4343 8.64462L61.5383 9.34963L59.3856 9.66693L60.2946 15.8334Z"
                fill="#FCFCFC" />
            <path d="M63.7755 15.4417L63.3203 8.51086L64.1262 8.45793L64.5814 15.3887L63.7755 15.4417Z" fill="#FCFCFC" />
            <path
                d="M67.2231 15.2765L66.4155 15.2671L66.4959 8.32183L70.3629 8.36661L70.3546 9.07919L67.2953 9.04376L67.2658 11.59L70.1398 11.6233L70.1317 12.3312L67.2576 12.2979L67.2231 15.2765Z"
                fill="#FCFCFC" />
            <path d="M71.7308 15.4233L72.3461 8.50482L73.1505 8.57636L72.5353 15.4948L71.7308 15.4233Z" fill="#FCFCFC" />
            <path
                d="M78.6382 16.3679L74.8263 15.7162L75.9969 8.86979L79.8088 9.52155L79.6887 10.224L76.6729 9.70834L76.2942 11.9233L79.1367 12.4094L79.0182 13.1024L76.1757 12.6164L75.7425 15.1499L78.7583 15.6655L78.6382 16.3679Z"
                fill="#FCFCFC" />
            <path
                d="M85.8414 14.6656C85.6225 15.4167 85.3026 16.0047 84.8819 16.4297C84.462 16.8517 83.9548 17.1146 83.3604 17.2185C82.77 17.3202 82.1053 17.2634 81.3664 17.0481L79.5556 16.5203L81.499 9.85204L83.5105 10.4382C84.1886 10.6359 84.736 10.9307 85.1529 11.3227C85.5707 11.7117 85.841 12.188 85.9637 12.7516C86.0904 13.313 86.0496 13.9511 85.8414 14.6656ZM85.0169 14.4551C85.1915 13.856 85.2352 13.3327 85.1479 12.885C85.0637 12.4382 84.856 12.0642 84.5248 11.7631C84.1975 11.4599 83.7556 11.2272 83.1991 11.065L82.0817 10.7394L80.5251 16.0804L81.4692 16.3556C82.3875 16.6232 83.1423 16.5974 83.7335 16.2781C84.3277 15.9598 84.7555 15.3521 85.0169 14.4551Z"
                fill="#FCFCFC" />
            <path
                d="M100.693 19.0783C100.375 18.8644 100.052 18.7268 99.7224 18.6656C99.3976 18.6036 99.0761 18.6163 98.7577 18.7036C98.4437 18.7901 98.1413 18.9474 97.8504 19.1754C97.5595 19.4034 97.2912 19.7 97.0454 20.0653C96.7201 20.5488 96.5121 21.0196 96.4214 21.4778C96.335 21.9351 96.3762 22.3617 96.545 22.7578C96.7163 23.1555 97.0279 23.5065 97.4799 23.8106C97.7374 23.9839 97.9933 24.1256 98.2476 24.2356C98.5062 24.3448 98.7654 24.439 99.0251 24.5183L98.6325 25.1017C98.3693 25.039 98.1097 24.9483 97.8537 24.8295C97.5959 24.7133 97.3081 24.5482 96.9901 24.3343C96.4041 23.94 95.9954 23.4893 95.7639 22.9823C95.5369 22.4745 95.4742 21.9322 95.5759 21.3555C95.6803 20.7806 95.9359 20.191 96.3426 19.5866C96.6361 19.1504 96.9644 18.793 97.3275 18.5143C97.695 18.2348 98.0841 18.0423 98.4948 17.9369C98.9081 17.8333 99.3333 17.8255 99.7704 17.9135C100.212 18.0006 100.655 18.1936 101.099 18.4924C101.39 18.6887 101.652 18.9068 101.884 19.1467C102.116 19.3866 102.304 19.6339 102.45 19.8884L101.8 20.2756C101.673 20.0677 101.519 19.8593 101.339 19.6503C101.163 19.4405 100.948 19.2498 100.693 19.0783Z"
                fill="#FCFCFC" />
            <path
                d="M102.809 28.7606L99.8556 26.264L104.34 20.9596L107.293 23.4562L106.833 24.0004L104.496 22.0252L103.046 23.7414L105.248 25.6031L104.794 26.14L102.592 24.2783L100.932 26.2412L103.269 28.2164L102.809 28.7606Z"
                fill="#FCFCFC" />
            <path
                d="M110.133 26.234C110.527 26.6458 110.801 27.0348 110.954 27.401C111.112 27.7674 111.15 28.1166 111.069 28.4486C110.988 28.7806 110.786 29.101 110.463 29.4098C110.198 29.6639 109.93 29.8283 109.659 29.903C109.391 29.9756 109.128 29.9866 108.869 29.9362C108.61 29.8857 108.367 29.8009 108.139 29.6817L107.202 33.2163L106.551 32.5368L107.468 29.3042L106.47 28.2609L104.376 30.265L103.818 29.6816L108.835 24.8784L110.133 26.234ZM109.595 26.6826L108.889 25.9448L106.964 27.7878L107.726 28.584C108.118 28.9935 108.489 29.2144 108.84 29.2467C109.194 29.2813 109.533 29.1431 109.858 28.8321C110.198 28.5057 110.341 28.1717 110.286 27.83C110.233 27.4906 110.003 27.1081 109.595 26.6826Z"
                fill="#FCFCFC" />
            <path
                d="M109.107 35.6587L108.61 35.016L113.541 31.2038L112.205 29.4748L112.769 29.039L115.933 33.1322L115.369 33.568L114.038 31.8465L109.107 35.6587Z"
                fill="#FCFCFC" />
            <path d="M110.919 38.1669L116.723 34.3527L117.167 35.0277L111.362 38.8418L110.919 38.1669Z" fill="#FCFCFC" />
            <path
                d="M112.821 41.1839L112.431 40.4767L118.512 37.1218L120.38 40.5079L119.756 40.8522L118.279 38.1732L116.049 39.4032L117.437 41.9199L116.817 42.2618L115.429 39.7451L112.821 41.1839Z"
                fill="#FCFCFC" />
            <path d="M114.89 45.149L121.215 42.2773L121.548 43.0126L115.224 45.8844L114.89 45.149Z" fill="#FCFCFC" />
            <path
                d="M117.369 51.3609L116.051 47.7252L122.581 45.3585L123.899 48.9943L123.229 49.2371L122.186 46.3606L120.073 47.1263L121.056 49.8375L120.395 50.0771L119.412 47.3659L116.996 48.2417L118.039 51.1181L117.369 51.3609Z"
                fill="#FCFCFC" />
            <path
                d="M122.483 57.1152C121.721 57.2939 121.052 57.3045 120.475 57.1471C119.901 56.9889 119.423 56.6766 119.04 56.2101C118.661 55.7459 118.384 55.1392 118.208 54.3899L117.777 52.5537L124.539 50.9673L125.018 53.007C125.179 53.6946 125.192 54.3163 125.055 54.8721C124.922 55.4271 124.64 55.8967 124.21 56.2807C123.783 56.667 123.208 56.9452 122.483 57.1152ZM122.261 56.2938C122.868 56.1513 123.346 55.932 123.693 55.6359C124.04 55.3429 124.264 54.9781 124.363 54.5417C124.466 54.1076 124.451 53.6084 124.319 53.0441L124.053 51.9109L118.637 53.1816L118.862 54.139C119.08 55.0702 119.474 55.7147 120.042 56.0726C120.612 56.4335 121.351 56.5072 122.261 56.2938Z"
                fill="#FCFCFC" />
            <circle cx="67.4997" cy="67.4999" r="64.7635" stroke="#FCFCFC" stroke-width="0.608108" />
            <circle cx="67.5002" cy="67.5" r="48.3446" stroke="#FCFCFC" stroke-width="0.608108" />
            <circle cx="67.5" cy="67.5" r="44.6959" stroke="#FCFCFC" stroke-width="0.608108" stroke-linejoin="round"
                stroke-dasharray="0.61 1.82" />
            <g clip-path="url(#clip0_2584_36942)">
                <path
                    d="M50.5508 64.9743L50.1778 64.5896L47.7403 67.1119L41.9374 73.106L38.4297 69.4973L35.9922 72.0196L39.873 76.013C40.4224 76.5769 41.1663 76.8931 41.9416 76.8921C42.7168 76.8911 43.46 76.5729 44.008 76.0076L50.5604 69.2406C51.1076 68.6734 51.414 67.9054 51.4122 67.1054C51.4103 66.3054 51.1005 65.5388 50.5508 64.9743Z"
                    fill="#FCFCFC" />
                <path
                    d="M36.1207 67.122L41.9244 61.1252L46.7618 66.0978L49.1993 63.5754L43.988 58.2236C43.4393 57.6592 42.6959 57.3423 41.9209 57.3423C41.1459 57.3423 40.4025 57.6592 39.8538 58.2236L33.3075 64.9933C32.7601 65.5592 32.4526 66.3259 32.4526 67.1251C32.4526 67.9244 32.7601 68.6911 33.3075 69.2569L35.0076 71.0055L37.4451 68.4831L36.1207 67.122Z"
                    fill="#FCFCFC" />
                <path
                    d="M59.2484 63.0166C60.6019 63.0166 61.5577 63.3286 62.1157 63.9526C62.6737 64.5765 62.9532 65.6335 62.9544 67.1235C62.9544 68.6146 62.6748 69.6689 62.1157 70.2863C61.5566 70.9036 60.6008 71.2129 59.2484 71.2141C57.8843 71.2141 56.9206 70.9048 56.3574 70.2863C55.7942 69.6677 55.5123 68.6134 55.5117 67.1235C55.5117 65.6335 55.7936 64.5765 56.3574 63.9526C56.9212 63.3286 57.8849 63.0166 59.2484 63.0166ZM59.2484 64.5552C58.5494 64.5552 58.0779 64.7321 57.8339 65.0858C57.5899 65.4395 57.4667 66.1187 57.4643 67.1235C57.4643 68.1174 57.5875 68.7912 57.8339 69.1449C58.0803 69.4986 58.5518 69.6758 59.2484 69.6764C59.9246 69.6764 60.3885 69.4992 60.6401 69.1449C60.8917 68.7906 61.0172 68.1168 61.0166 67.1235C61.0166 66.1193 60.8911 65.4401 60.6401 65.0858C60.3891 64.7315 59.9251 64.5546 59.2484 64.5552Z"
                    fill="#FCFCFC" />
                <path
                    d="M70.6429 69.4542L70.8124 70.7234C70.0634 71.0387 69.1405 71.1964 68.0437 71.1964C66.7012 71.1964 65.753 70.8898 65.1991 70.2766C64.6452 69.6634 64.3686 68.6065 64.3691 67.1057C64.3691 65.6043 64.6484 64.5471 65.207 63.9339C65.7655 63.3207 66.7164 63.0142 68.0595 63.0142C69.1149 63.0142 69.9914 63.1622 70.6892 63.4583L70.4734 64.6636C69.9713 64.6425 69.2744 64.632 68.3827 64.632C67.6034 64.632 67.0652 64.8065 66.7682 65.1554C66.4712 65.5043 66.3224 66.1544 66.3218 67.1057C66.3218 68.0666 66.4706 68.7245 66.7682 69.0794C67.0658 69.4343 67.604 69.6112 68.3827 69.61C69.1388 69.6158 69.8943 69.5637 70.6429 69.4542Z"
                    fill="#FCFCFC" />
                <path
                    d="M75.6395 63.0166C76.9931 63.0166 77.9491 63.3286 78.5077 63.9526C79.0662 64.5765 79.3455 65.6335 79.3455 67.1235C79.3455 68.6146 79.0662 69.6689 78.5077 70.2863C77.9491 70.9036 76.9931 71.2129 75.6395 71.2141C74.276 71.2141 73.3123 70.9048 72.7485 70.2863C72.1847 69.6677 71.9028 68.6134 71.9028 67.1235C71.9028 65.6335 72.1847 64.5765 72.7485 63.9526C73.3123 63.3286 74.276 63.0166 75.6395 63.0166ZM75.6395 64.5552C74.9405 64.5552 74.4691 64.7321 74.225 65.0858C73.981 65.4395 73.8578 66.1187 73.8555 67.1235C73.8555 68.1174 73.9786 68.7912 74.225 69.1449C74.4714 69.4986 74.9429 69.6758 75.6395 69.6764C76.3168 69.6764 76.7808 69.4992 77.0312 69.1449C77.2817 68.7906 77.4072 68.1168 77.4078 67.1235C77.4078 66.1193 77.2822 65.4401 77.0312 65.0858C76.7802 64.7315 76.3163 64.5546 75.6395 64.5552Z"
                    fill="#FCFCFC" />
                <path
                    d="M84.4964 63.0166C85.85 63.0166 86.8061 63.3286 87.3646 63.9526C87.9232 64.5765 88.2025 65.6335 88.2025 67.1235C88.2025 68.6146 87.9232 69.6689 87.3646 70.2863C86.8061 70.9036 85.85 71.2129 84.4964 71.2141C83.1323 71.2141 82.1687 70.9048 81.6055 70.2863C81.0422 69.6677 80.7603 68.6134 80.7598 67.1235C80.7598 65.6335 81.0417 64.5765 81.6055 63.9526C82.1693 63.3286 83.1329 63.0166 84.4964 63.0166ZM84.4964 64.5552C83.7975 64.5552 83.326 64.7321 83.0819 65.0858C82.8379 65.4395 82.7147 66.1187 82.7124 67.1235C82.7124 68.1174 82.8356 68.7912 83.0819 69.1449C83.3283 69.4986 83.7998 69.6758 84.4964 69.6764C85.1738 69.6764 85.6377 69.4992 85.8881 69.1449C86.1386 68.7906 86.2641 68.1168 86.2647 67.1235C86.2647 66.1193 86.1392 65.4401 85.8881 65.0858C85.6371 64.7315 85.1732 64.5546 84.4964 64.5552Z"
                    fill="#FCFCFC" />
                <path
                    d="M95.0617 63.0166L94.8616 64.8246H94.3235C93.7905 64.8246 92.96 65.057 91.8318 65.5218V71.0078H89.9561V63.2391H91.4168L91.5863 64.3805C92.6626 63.4712 93.6979 63.0166 94.6922 63.0166H95.0617Z"
                    fill="#FCFCFC" />
                <path
                    d="M103.104 59.9082V71.0064H101.628L101.474 69.9443C100.601 70.7791 99.6267 71.1967 98.5527 71.1973C97.6324 71.1955 96.9606 70.8493 96.5372 70.1587C96.1137 69.4681 95.9038 68.4399 95.9073 67.0743C95.9073 65.5843 96.1402 64.5354 96.6062 63.9277C97.0721 63.3199 97.8025 63.0158 98.7973 63.0152C99.7607 63.0152 100.596 63.2425 101.304 63.6971C101.248 63.1812 101.222 62.6623 101.227 62.1432V59.9082H103.104ZM101.228 68.7552V64.9024C100.408 64.691 99.7822 64.5853 99.3512 64.5853C98.7688 64.5853 98.3689 64.7465 98.1517 65.069C97.9344 65.3915 97.827 66.0548 97.8293 67.059C97.8293 68.0415 97.9344 68.7048 98.1447 69.0489C98.355 69.393 98.7213 69.5648 99.2438 69.5642C99.5541 69.5712 99.8626 69.5146 100.151 69.3975C100.528 69.2161 100.888 69.0012 101.228 68.7552Z"
                    fill="#FCFCFC" />
            </g>
            <path
                d="M36.1828 17.6353L36.7972 19.5261H38.7853L37.1769 20.6947L37.7912 22.5856L36.1828 21.417L34.5743 22.5856L35.1887 20.6947L33.5802 19.5261H35.5684L36.1828 17.6353Z"
                fill="#FCFCFC" />
            <path
                d="M91.5202 13.3784L92.1346 15.2693H94.1227L92.5143 16.4379L93.1286 18.3288L91.5202 17.1601L89.9117 18.3288L90.5261 16.4379L88.9176 15.2693H90.9058L91.5202 13.3784Z"
                fill="#FCFCFC" />
            <path
                d="M123.75 59.5945L124.365 61.4853H126.353L124.744 62.654L125.359 64.5448L123.75 63.3762L122.142 64.5448L122.756 62.654L121.148 61.4853H123.136L123.75 59.5945Z"
                fill="#FCFCFC" />
            <path
                d="M100.034 110.676L100.648 112.567H102.636L101.028 113.735L101.642 115.626L100.034 114.458L98.4254 115.626L99.0398 113.735L97.4313 112.567H99.4195L100.034 110.676Z"
                fill="#FCFCFC" />
            <path
                d="M42.8717 115.541L43.4861 117.431H45.4743L43.8658 118.6L44.4802 120.491L42.8717 119.322L41.2633 120.491L41.8777 118.6L40.2692 117.431H42.2574L42.8717 115.541Z"
                fill="#FCFCFC" />
            <path
                d="M11.8581 68.1082L12.4724 69.999H14.4606L12.8522 71.1676L13.4665 73.0585L11.8581 71.8899L10.2496 73.0585L10.864 71.1676L9.25552 69.999H11.2437L11.8581 68.1082Z"
                fill="#FCFCFC" />
            <defs>
                <clipPath id="clip0_2584_36942">
                    <rect width="71.0921" height="19.9324" fill="white" transform="translate(32.4521 57.1155)" />
                </clipPath>
            </defs>
        </svg> `,
                fit: [150, 150],
                absolutePosition: { x: 350, y: 430 }
            }
        ]
    };
    pdfMake.createPdf(docDefinition).open();
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, OverlayTrigger, ProgressBar, Spinner, Tooltip } from 'react-bootstrap';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import axios from 'axios';
import Select from "react-select";
import styled from 'styled-components';

import libraryIcon from '../../assets/images/sidebar-library-normal.svg';
import infoGreen from '../../assets/images/info-green.svg';

import { loadLibrary, saveLibraryData } from '../../actions';
import { Input } from '../Input';
import { DropDownStyle, TaskFrequency, isMongoObjectId } from '../../helpers';
import { Button } from '../Button';

const StyledCard = styled.div`
  background: #ffffff;
  border: 1px solid #d3d3d3;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 7px;
`;

const SetupCard = styled(StyledCard)`
min-width:50%;
max-width: 80%;
min-height:20%;
margin-top: 1rem;

.outerDiv {
    max-width: 100%;
    overflow-x: hidden;
    padding: 15px;

    @media (max-width:480px) {
        width: 100%;
        height: 100%;
        overflow-x: auto;
    }

    @media only screen and (max-width:1024px) and (min-width: 481px) {
        width: 100%;
        height: 100%;
        overflow-x: auto;
    }
}

.fa {
    font-size: 16px;
}

.fa-plus-circle {
    color: #008000ad;
    margin-top: 9px;
}

.fa-times-circle {
    color: red;
    margin-top: 9px;
}

h5 {
    line-height: 25px;
    max-width: 100%;
}

.filled-button:disabled {
    cursor: not-allowed;
    background: #FBFBFB;
    border: 0.5px solid #EAEAEA;
    color: #BCBCBC;
}

.firstDiv {
    width: 750px;

    @media (max-width:480px) {
        display: block;
        width: 100%;
        margin: 2rem 0rem;
    }

    @media only screen and (max-width:1024px) and (min-width: 481px) {
        display: block;
        width: 100%;
        margin: 2rem 0rem;
    }
}

.h2 {
    display: flex;
    align-items: center;
}

img {
    margin-right: 5px;
}

.table {
    width: 100%;
    padding: 15px;
    border-radius: 7px;
    border: 1px solid #d3d3d3;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);

    thead {
        padding: 10px;
        text-align: center;

        th {
            font-size: 14px;
        }
    }

    .icon-th {
        width: 10px;
    }
}

.footer {
    .proMar {
        align-items: center;
        margin-top: 15px;
        width: 75%;

        @media (max-width:480px) {
            max-width: 69%;
        }
    }

    .proText {
        margin-top: 12px;
        margin-left: 30px;
        font-size: 14px;
        white-space: nowrap;
    }
}

.answer-container {
    max-height: 45vh;
    overflow-y: auto;

    @media (max-width:1024px) {
        max-height: 100vh;
    }
}
`;

const HeadersContainer = styled.div`
    font-weight:700 !important;
    color:black;
    display:flex;
    margin-bottom:1rem;
`;

const RadioInput = styled.input`
    accent-color: green;
    height:20px;
    width:20px;
    margin-right: 0.5rem;
    cursor: pointer;
`;

const ErrorContainer = styled(Form.Control.Feedback)`
    font-weight:600 !important;
    margin-top: 0rem !important;
    font-size: 1rem !important;
`;

const QuestionContainer = styled.h5`
  font-weight:600 !important;
`;

class LibrarySetup extends Component {

  state = {
    inputTypes: [],
    inputTypeNo: undefined,
    formData: {
      singleAns: '',
      multipleAns: [''],
      booleanAns: undefined,
      tableAns: [
        {}
      ]
    },
    columns: undefined,
    isUpdated: false,
    isLoading: false,
    loading: true,
    isStepSave: false,
    validated: false,
    users: [],
    customOptions: []
  };

  componentDidMount() {
    this.getTreeData();
    this.getMasterData();

  };


  getMasterData = async () => {
    try {
      const { user: { location } } = this.props;
      const { data: { payload: users } } = await axios.get(`/api/v2/master/users_by_location/${location._id}`);
      this.setState({ users });
    } catch (error) {
      console.error(error);
    }
  };

  getTreeData = async () => {
    this.setState({ loading: true });
    const inputTypes = [];
    await this.props.dispatch(loadLibrary());
    const { user: { location }, tree } = this.props;
    tree.items && tree.items.forEach((item) => {
      inputTypes.push(item.fields.type);
    });
    let lastUpdatedStep = location.lastCompletedLibrarySetupStep;
    if (location.libraryData && location.libraryData.length > 0) {
      lastUpdatedStep = location.libraryData.length;
      this.setGotoSetUp(lastUpdatedStep);
    } else {
      this.setGotoSetUp(lastUpdatedStep - 1);
    }
    this.setState({ inputTypes, loading: false });
  };

  setGotoSetUp = (userStep) => {
    const { user: { location } } = this.props;
    const isLastCompletedLibrarySetupStep = location.lastCompletedLibrarySetupStep && location.lastCompletedLibrarySetupStep != 0;
    if (isLastCompletedLibrarySetupStep) {
      this.setState({ inputTypeNo: userStep });
    } else {
      this.setState({ inputTypeNo: 0 });
    }
    setTimeout(() => {
      const el = document.getElementById('0');
      if (el) {
        el.focus();
      };
    }, 50);
  };

  onTableInputChange = (rowIndex, colName, value) => {
    const { formData } = this.state;
    formData.tableAns[rowIndex][colName] = value;
    this.setState({ formData });
  };

  onMultiInputChange = (index, value) => {
    const { formData } = this.state;
    if (index === 'select') {
      formData.multipleAns = value;
    } else {
      formData.multipleAns[index] = value;
    }
    this.setState({ formData });
  };

  onSingleInputChange = (value) => {
    const { formData } = this.state;
    formData.singleAns = value;
    this.setState({ formData });
  };

  onBooleanInputChange = (value) => {
    const { formData } = this.state;
    formData.booleanAns = value;
    this.setState({ formData });
  };

  formatAnswers(data, type) {

    if (type === 'singleInput' || type === 'singleUserSelect') return data.singleAns.trim();
    if (type === 'multipleInput' || type === 'multipleUserSelect') return data.multipleAns.map(ans => ans.trim());
    if (type === 'boolean') return data.booleanAns;
    if (type === 'table') return data.tableAns;

  };

  goToNextStep = async (e, fields) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ validated: true });
    } else {
      const { inputTypes, inputTypeNo } = this.state;
      const isLast = (inputTypes.length === inputTypeNo + 1) ? true : false;
      this.setState({ isLoading: true, isStepSave: true });
      const ans = this.formatAnswers(this.state.formData, fields.type);

      const { dispatch, user, history } = this.props;
      try {
        const libraryData = {
          answer: ans,
          associatedVariable: fields.associatedVariable,
          type: fields.type,
          stepNumber: fields.stepNumber,
          question: fields.descriptionBody.content[0].content[0].value,
          isTaskForThisStep: fields.isTaskForThisStep,
          taskDetails: fields.isTaskForThisStep ? fields.taskDetails : [],
          tableLayout: fields.tableLayout || {}
        };

        const res = await dispatch(saveLibraryData({ libraryData, isLast, locationId: user.location._id }));
        if (res) {
          if (!isLast) {
            const newStep = this.state.inputTypeNo + 1;
            let backObj = { inputTypeNo: newStep };
            backObj.formData = this.getFormData(newStep);
            this.setState(backObj);
            document.getElementById('0')?.focus();
          } else {
            history.push('/library');
          }
          this.setState({ isStepSave: false, isLoading: false });
        };
      } catch (error) {
        this.setState({ isStepSave: false, isLoading: false });
      };
      this.setState({ validated: false });
    }
  };

  getFormData = (newStep) => {
    const { tree, user } = this.props;
    const backStep = tree.items[newStep];

    const backDBStep = user.location.libraryData.find((step) => step.associatedVariable === backStep.fields.associatedVariable);
    const formData = { singleAns: '', multipleAns: [''], booleanAns: undefined, tableAns: [{}] };
    if (backDBStep) {
      if (backStep.fields.type === 'singleInput' || backStep.fields.type === 'singleUserSelect') formData.singleAns = backDBStep.answer.trim();
      if (backStep.fields.type === 'multipleInput') formData.multipleAns = typeof backDBStep.answer === 'string' ? [backDBStep.answer] : backDBStep.answer.map(ans => ans.trim());


      if (backStep.fields.type === 'multipleUserSelect') {
        formData.multipleAns = typeof backDBStep.answer === 'string' ? [backDBStep.answer] : backDBStep.answer.map(ans => ans.trim());
        const otherOptions = this.state.users.filter(u => !u.other) || [];
        formData.multipleAns.forEach(op => {
          !isMongoObjectId(op) && otherOptions.push({ label: op, value: op, other: true });
        });
        this.setState({ users: otherOptions });
      };

      if (backStep.fields.type === 'singleUserSelect') {
        const otherOptions = this.state.users.filter(u => !u.other) || [];
        !isMongoObjectId(formData.singleAns) && otherOptions.push({ label: formData.singleAns, value: formData.singleAns, other: true });
        this.setState({ users: otherOptions });
      };

      if (backStep.fields.type === 'boolean') formData.booleanAns = backDBStep.answer;
      if (backStep.fields.type === 'table') formData.tableAns = backDBStep.answer;
    };
    return formData;
  };

  goToPreviousStep = () => {
    const newStep = this.state.inputTypeNo - 1;
    const backObj = { inputTypeNo: newStep };
    backObj.formData = this.getFormData(newStep);
    this.setState({ ...backObj, isStepSave: false });
    setTimeout(() => {
      document.getElementById('0')?.focus();
    }, 50);
  };

  addNewRow = () => {
    const formData = this.state.formData;
    formData.tableAns.push({});
    this.setState({ formData: formData });
  };

  removeRow = (e, index) => {
    e.preventDefault();
    const formData = { ...this.state.formData };
    formData.tableAns.splice(index, 1);
    this.setState({ formData: formData });
  };

  addMultipleInputs = () => {
    const formData = { ...this.state.formData };
    formData.multipleAns.push('');
    this.setState({ formData: formData });
  };

  removeMultipleInputs = (e, index) => {
    e.preventDefault();
    const formData = { ...this.state.formData };
    formData.multipleAns.splice(index, 1);
    this.setState({ formData: formData });
  };

  onInputChange = (e, type) => {

    if (e.target.value.trim() && e.key === 'Enter') {
      const { users, formData } = this.state;
      const check = users.some(u => u.value === e.target.value.trim());
      if (!check) {
        e.preventDefault();
        if (type === 'multi') {
          this.setState({ users: [...users, { label: e.target.value.trim(), value: e.target.value.trim(), other: true }], formData: { ...formData, multipleAns: [...formData.multipleAns, e.target.value.trim()] } });
        } else {
          this.setState({ users: [...users, { label: e.target.value.trim(), value: e.target.value.trim(), other: true }], formData: { ...formData, singleAns: e.target.value.trim() } });
        }
      };
      e.target.value = '';
    };
  };

  getColWidth = (col_count) => (90 / col_count + '%');

  render() {
    const { tree } = this.props;
    const { inputTypes, isStepSave, formData, inputTypeNo, validated, loading, users } = this.state;

    return (
      <SetupCard>
        {loading ?
          <div className='w-100 text-center my-5'>
            <Spinner animation="border" variant="success" />
          </div> :
          <div className="outerDiv">
            <HeadersContainer>
              <img src={libraryIcon} alt="Icon" className='mr-3' />
              <h2>Library Setup</h2>
            </HeadersContainer>
            <Form noValidate validated={validated} onSubmit={(e) => this.goToNextStep(e, tree.items[inputTypeNo].fields)}>
              <div>
                <QuestionContainer className="mb-2">
                  {documentToReactComponents(tree.items[inputTypeNo].fields.descriptionBody)}
                </QuestionContainer>
                {
                  inputTypes[inputTypeNo] === 'singleInput' &&
                  <div>
                    <Input
                      type="text"
                      id='0'
                      name={tree.items[inputTypeNo].fields.associatedVariable}
                      value={formData.singleAns}
                      onChange={(e) => this.onSingleInputChange(e.target.value)}
                      autoComplete='off'
                      className='col-lg-6 col-md-12 col-sm-12 mx-1'
                      required
                    />
                    <ErrorContainer type="invalid">Required</ErrorContainer>
                  </div>
                }
                {
                  inputTypes[inputTypeNo] === 'singleUserSelect' &&
                  <div>
                    <div className='d-flex'>
                      <Select
                        name={tree.items[inputTypeNo].fields.associatedVariable}
                        value={users.find(u => u.value === formData.singleAns)}
                        menuPosition="fixed"
                        styles={DropDownStyle}
                        options={users}
                        onKeyDown={this.onInputChange}
                        className='col-lg-6 col-md-12 col-sm-12 mx-1 p-0'
                        onChange={(e) => this.onSingleInputChange(e.value)}
                      />
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            <strong>If you want to enter a custom value, input the value and press Enter.</strong>
                          </Tooltip>
                        }>
                        <img className='cursor-pointer' src={infoGreen} alt="info_green" />
                      </OverlayTrigger>
                    </div>
                    <input type="text" value={formData.singleAns} onChange={() => { }} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px' }} />
                    <ErrorContainer type="invalid">Required</ErrorContainer>
                  </div>
                }
                {
                  inputTypes[inputTypeNo] === 'multipleUserSelect' &&
                  <div>
                    <div className='d-flex'>
                      <Select
                        name={tree.items[inputTypeNo].fields.associatedVariable}
                        value={users.filter(u => formData.multipleAns.includes(u.value))}
                        menuPosition="fixed"
                        styles={DropDownStyle}
                        options={users}
                        onKeyDown={this.onInputChange}
                        isMulti={true}
                        className='col-lg-6 col-md-12 col-sm-12 mx-1 p-0'
                        onChange={(e) => { this.onMultiInputChange('select', e.map(u => u.value)) }}
                      />
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            <strong>If you want to enter a custom value, input the value and press Enter.</strong>
                          </Tooltip>
                        }>
                        <img className='cursor-pointer' src={infoGreen} alt="info_green" />
                      </OverlayTrigger>
                    </div>
                    <input type="text" value={formData.multipleAns.toString()} onChange={() => { }} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px' }} />
                    <ErrorContainer type="invalid">Required</ErrorContainer>
                  </div>
                }
                {
                  inputTypes[inputTypeNo] === 'boolean' &&
                  <div>
                    <div className='d-flex'>
                      <div className='d-flex mr-4'>
                        <RadioInput
                          id='0'
                          name='inlineRadio'
                          type="radio"
                          value='true'
                          defaultChecked={formData.booleanAns && formData.booleanAns !== undefined}
                          onChange={(e) => this.onBooleanInputChange(true)}
                          required
                        />
                        <h5>Yes</h5>
                      </div>
                      <div className='d-flex'>
                        <RadioInput
                          type="radio"
                          name='inlineRadio'
                          value='false'
                          defaultChecked={!formData.booleanAns && formData.booleanAns !== undefined}
                          onChange={(e) => this.onBooleanInputChange(false)}
                          required
                        />
                        <h5>No</h5>
                      </div>
                    </div>
                    {(formData.booleanAns === undefined && validated) && <p style={{ fontWeight: '600', fontSize: '1rem' }} className='my-0 text-danger'>Required</p>}
                  </div>
                }
                {
                  inputTypes[inputTypeNo] === 'table' &&
                  <div className='answer-container'>
                    <table className="table">
                      <thead>
                        <tr>
                          {
                            tree.items[inputTypeNo].fields.tableLayout.columns.map((columnName, colIndex) =>
                              <th style={{ width: this.getColWidth(tree.items[inputTypeNo].fields.tableLayout.columns.length) }} key={colIndex}>
                                {columnName}
                              </th>
                            )
                          }
                          <th className="icon-th"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          formData.tableAns.length > 0 &&
                          formData.tableAns.map((ans, index) => (
                            <tr key={index}>
                              {
                                tree.items[inputTypeNo].fields.tableLayout.columns.map((columnName, index1) =>
                                  <td key={index1} style={{ width: this.getColWidth(tree.items[inputTypeNo].fields.tableLayout.columns.length) }}>
                                    {
                                      (tree.items[inputTypeNo].fields.tableLayout.userSelectColumns && tree.items[inputTypeNo].fields.tableLayout.userSelectColumns.includes(columnName)) ?
                                        <div>
                                          <input type="text" value={ans[columnName] || ''} onChange={() => { }} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px', position: 'absolute' }} />
                                          <Select
                                            name={columnName}
                                            value={users.find(u => u.value === ans[columnName]) || null}
                                            menuPosition="fixed"
                                            styles={DropDownStyle}
                                            options={users}
                                            onChange={(e) => this.onTableInputChange(index, columnName, e.value)}
                                          />
                                          <ErrorContainer type="invalid">Required</ErrorContainer>
                                        </div>
                                        :
                                        (tree.items[inputTypeNo].fields.tableLayout.frequencySelectColumns && tree.items[inputTypeNo].fields.tableLayout.frequencySelectColumns.includes(columnName)) ?
                                          <div>
                                            <input type="text" value={ans[columnName] || ''} onChange={() => { }} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px', position: 'absolute' }} />
                                            <Select
                                              name={columnName}
                                              value={TaskFrequency.find(u => u.value === ans[columnName]) || null}
                                              menuPosition="fixed"
                                              styles={DropDownStyle}
                                              options={TaskFrequency}
                                              onChange={(e) => this.onTableInputChange(index, columnName, e.value)}
                                            />
                                            <ErrorContainer type="invalid">Required</ErrorContainer>
                                          </div>
                                          :
                                          <div>
                                            <Input
                                              id={index1 === index ? '0' : '1'}
                                              type="text"
                                              name={columnName}
                                              value={ans[columnName] || ''}
                                              onChange={(e) => this.onTableInputChange(index, columnName, e.target.value)}
                                              required
                                            />
                                            <ErrorContainer type="invalid">Required</ErrorContainer>
                                          </div>
                                    }
                                  </td>
                                )
                              }
                              <td className="d-flex">
                                {
                                  formData.tableAns.length === index + 1 &&
                                  <i className="fa fa-plus-circle ml-2" onClick={this.addNewRow} />
                                }
                                {
                                  formData.tableAns.length > 1 && <i className="fa fa-times-circle ml-2" onClick={(e) => this.removeRow(e, index)} />
                                }
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                }
                {
                  inputTypes[inputTypeNo] === "multipleInput" &&
                  <div className='answer-container'>
                    {formData.multipleAns && formData.multipleAns.length > 0 &&
                      formData.multipleAns.map((ans, index) => (
                        <div key={index}>
                          <Input
                            id={index}
                            type="text"
                            name={tree.items[inputTypeNo].fields.associatedVariable}
                            value={ans}
                            autoComplete='off'
                            onChange={(e) => this.onMultiInputChange(index, e.target.value)}
                            className='col-lg-6 col-md-12 col-sm-12 mx-1'
                            required
                          />
                          {
                            formData.multipleAns.length === index + 1 && <i className="fa fa-plus-circle ml-2" onClick={this.addMultipleInputs} />
                          }
                          {
                            formData.multipleAns.length > 1 && <i className="fa fa-times-circle ml-2" onClick={(e) => this.removeMultipleInputs(e, index)} />
                          }
                          <br />
                          <ErrorContainer type="invalid">Required</ErrorContainer>
                        </div>
                      ))}
                  </div>
                }
              </div>
              <div className="footer mt-2">
                <div className="row d-flex align-items-center justify-content-between ">
                  <div className="col-lg-2 col-md-2 col-sm-2 col-xs-1 justify-content-start d-flex">
                    <Button tabIndex="-1" onClick={this.goToPreviousStep} disabled={inputTypeNo == 0} type='button'>
                      {'<< Back'}
                    </Button>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 d-flex">
                    <div className="proMar">
                      <ProgressBar variant="success" now={inputTypeNo + 1} max={inputTypes.length} key={1} />
                    </div>
                    <span className="proText">{inputTypeNo + 1} of {inputTypes.length} </span>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 col-xs-1 justify-content-end d-flex">
                    {inputTypes.length > inputTypeNo + 1 ?
                      <Button
                        disabled={isStepSave}
                        type='submit'
                      >
                        {'Next >>'}
                      </Button>
                      :
                      <Button type='submit'>
                        Save
                      </Button>}
                  </div>
                </div>
              </div>
            </Form>
          </div>
        }
      </SetupCard>
    );
  }
};

const mapStateToProps = (state) => ({
  tree: state?.library?.tree,
  user: state.user.currentUser
});

export default connect(mapStateToProps)(LibrarySetup);
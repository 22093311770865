import { useEffect, useState } from "react";
import axios from "axios";
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { NameBlock, NameTitle, StaffViewContainer, StaffViewHeaderRow, InputWithLabel, LabelText, Pipe, SectionBlock, SectionHeading, SubText, NavigationTabSection, NavTab, InfoCard, CustomCard, CardBody, IconContainer, ImageContainer, IconButton } from "./Style";
import { Alert, Button, Col, Figure, Modal, ModalBody, ModalFooter, Row, Spinner, Table } from "react-bootstrap";
import { DropDownStyle, generateCertificate } from "../../helpers";
import pencilGreen from '../../assets/images/pencil-green.svg';
import uploadDocGreen from '../../assets/images/upload-doc-green.svg';
import { DetailContainer } from "../Dashboard/Style";
import moment from "moment";
import Swal from "sweetalert2";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

// Set the fonts for pdfmake
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { HealthRecordForm, HorizontalProgressBar, EmergencyContact, StatusBadge, TransparentButton, ResendInviteModal } from "../../components";

import fluidResistantGown from '../../assets/safety-equipment/fluidResistantGown.svg';
import safetyGoggles from '../../assets/safety-equipment/safetyGoggles.svg';
import latexGloves from '../../assets/safety-equipment/latexGloves.svg';
import heavyDutyGloves from '../../assets/safety-equipment/heavyDutyGloves.svg';
import mask from '../../assets/safety-equipment/mask.svg';
import customSafetyEquipment from '../../assets/safety-equipment/customSafetyEquipment.svg';
import faceShieldApron from '../../assets/safety-equipment/faceShieldApron.svg';
import leadApron from '../../assets/safety-equipment/leadApron.svg';
import clinicalJacket from '../../assets/safety-equipment/clinicalJacket.svg';
import certificate from '../../assets/images/certificate.svg';
import refreshIcon from '../../assets/images/green_refresh.svg';

const icons = { faceShieldApron, fluidResistantGown, laserSafetyGoggles: safetyGoggles, eyewear: safetyGoggles, safetyGoggles, latexGloves, heavyDutyGloves, respiratoryMaskN95: mask, fitTestedRespiratoryMaskN95: mask, mask, customSafetyEquipment, leadApron, clinicalJacket };
const positions = [
    { label: 'Administrator', value: 'administrator' },
    { label: 'Non Administrator', value: 'non-administrator' }
];

const NameBlockElement = ({ firstName, lastName, height, fontSize }) => {
    lastName = lastName ? lastName : ' ';
    height = height ? height : '2.5rem';
    fontSize = fontSize ? fontSize : '0.875em';
    return (
        <NameBlock fontSize={fontSize} height={height}>
            {firstName[0] + lastName[0]}
        </NameBlock>
    );
};

const StaffDetails = ({ user }) => {
    const currentUser = useSelector(state => state.user.currentUser);
    const history = useHistory();
    const searchParams = new URLSearchParams(history.location.search);
    const userId = user?._id ? user._id : searchParams.get('userId');

    const [staffLoading, setStaffLoading] = useState(true);
    const [editStaffOpen, setEditStaffOpen] = useState(false);
    const [emergencyContactOpen, setEmergencyContactOpen] = useState(false);
    const [docUploadOpen, setDocOpenUpload] = useState(false);
    const [dataToUpdate, setDataToUpdate] = useState({});
    const [taskLoading, setTaskLoading] = useState(false);
    const [healthRecordType, setHealthRecordType] = useState([]);
    const [roles, setRoles] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [taskFilter, setTaskFilter] = useState({ type: 'assigned', limit: 5 });
    const [recordLoading, setRecordLoading] = useState(false);
    const [recordList, setRecordList] = useState([]);
    const [trainingLoading, setTrainingLoading] = useState(false);
    const [trainingList, setTrainingList] = useState([]);
    const [trainingType, setTrainingType] = useState('in-progress');
    const [staffDetails, setStaffDetails] = useState({});
    const [selectedLocation, setSelectedLocation] = useState(currentUser.location._id);
    const [selectedLocForTask, setSelectedLocForTask] = useState(currentUser.location._id);
    const [ppeLoading, setPPELoading] = useState(false);
    const [ppeList, setPPEList] = useState([]);
    const [ppeType, setPPEType] = useState('current');
    const [locationList, setLocationList] = useState([]);
    const [resendModalOpen, setResendModalOpen] = useState(false);
    const [selectLocationModal, setSelectedLocationModal] = useState(false);
    const [selectedLocationForTrainingCertificate, setSelectedLocationForTrainingCertificate] = useState(null);
    const [selectedTrainingForCertificate, setSelectedTrainingForCertificate] = useState({});

    useEffect(() => {
        getMasterData();
        getLocationRoles(selectedLocation);
        getStaffDetails(selectedLocation);
        getUsersTasks(taskFilter);
        getTrainingData(trainingType);
        getPPE(ppeType);
    }, []);

    const getMasterData = async () => {
        try {
            const { data: { payload } } = await axios.get(`/api/v2/master/health_record_types`);
            const { data: { payload: locationList } } = await axios.get(`/api/v2/master/locations_by_user/${currentUser._id}`);
            setHealthRecordType(payload);
            setLocationList(locationList);
        } catch (error) {
            console.log("🚀 ~ getMasterData ~ error:", error);
        }
    };

    const getLocationRoles = async (loc) => {
        try {
            const { data: { payload } } = await axios.get(`/api/v2/master/roles_by_location/${loc}`);
            setRoles(payload);
        } catch (error) {
            console.log("🚀 ~ getMasterData ~ error:", error);
        }
    };

    const getStaffDetails = async (loc) => {
        try {
            setStaffLoading(true);
            getHealthRecords(loc);
            const { data: { payload } } = await axios.get(`/api/v2/user_data/${userId}/${loc}`);
            setStaffDetails(payload);
            setStaffLoading(false);
        } catch (error) {
            setStaffLoading(false);
        }
    };

    const getPPE = async (type) => {
        try {
            setPPEType(type);
            setPPELoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/users_ppe/${type}/${userId}/${selectedLocation}`);
            setPPEList(payload);
            setPPELoading(false);
        } catch (error) {
            setPPELoading(false);
        }
    };

    const getHealthRecords = async (locationId) => {
        try {
            setRecordLoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/user_health_record/${userId}/${locationId}`);
            setRecordList(payload);
            setRecordLoading(false);
        } catch (error) {
            setRecordLoading(false);
            console.log("🚀 ~ getHealthRecords ~ error:", error);
        }
    };

    const getTrainingData = async (trainingType) => {
        try {
            setTrainingLoading(true);
            setTrainingType(trainingType);
            const { data: { payload } } = await axios.get(`/api/v2/users_training_details/${trainingType}/${userId}`);
            setTrainingList(payload);
            setTrainingLoading(false);
        } catch (error) {
            console.log("🚀 ~ getTrainingData ~ error:", error);
            setTrainingLoading(false);
        }
    };

    const getUsersTasks = async (filter, loc) => {
        try {
            if (loc) setSelectedLocForTask(loc);

            setTaskLoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/user_tasks/${userId}/${loc ? loc : selectedLocForTask}`, { params: filter });
            setTaskList(payload);
            setTaskLoading(false);
        } catch (error) {
            setTaskLoading(false);
            console.log("🚀 ~ getUsersTasks ~ error:", error);
        }
    };

    const getTaskOnChanges = (type, limit) => {
        setTaskFilter({ type, limit });
        getUsersTasks({ type, limit });
    };

    const editStaffDetails = () => {
        let disabled = false;
        let adminCounts = locationList.find((loc) => loc.value === selectedLocation);
        adminCounts = adminCounts ? adminCounts.admin : 0;
        if (staffDetails.isRegistrationCompleted == 2 && staffDetails.adminLocations.includes(selectedLocation) && adminCounts <= 1) {
            disabled = true;
        };
        setDataToUpdate({ roles: staffDetails.roles || [], safetyEquipments: staffDetails.safetyEquipments || [], adminLocations: staffDetails.adminLocations || [], disabled, temp_position: staffDetails?.adminLocations.includes(selectedLocation) ? 'administrator' : 'non-administrator' });
        setEditStaffOpen(true);
    };

    const onDropDownChange = (type, data) => {
        const temp_data = { ...dataToUpdate };
        console.log("🚀 ~ onDropDownChange ~ temp_data:", temp_data)
        if (type === 'role') {
            const current_role = roles.map(role => {
                if (role.location === data.location) {
                    return role.value;
                };
            }) || [];

            temp_data.roles = temp_data.roles.filter(role => !current_role.includes(role));
            temp_data.roles = [...new Set([...temp_data.roles || [], data.value])];
        } else if (type === 'position') {
            temp_data.temp_position = data.value;
            if (data.value === 'administrator') {
                temp_data.adminLocations = [...new Set([...temp_data.adminLocations, selectedLocation])];
            } else {
                temp_data.adminLocations = temp_data.adminLocations.filter(lo => lo !== selectedLocation);
            };
        };
        setDataToUpdate(temp_data);
    };

    const saveUserRoleAndPosition = async (e) => {
        try {
            e.preventDefault();
            await axios.put(`/api/v2/update_user_role_position/${staffDetails._id}`, dataToUpdate);
            await getStaffDetails(selectedLocation);
            Swal.fire({
                icon: 'success',
                title: 'User has successfully updated',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(() => {
                setEditStaffOpen(false);
            });
        } catch (error) {
            console.log("🚀 ~ saveUserRoleAndPosition ~ error:", error);
        }
    };

    const handleClose = () => {
        setSelectedLocationModal(false);
        setSelectedTrainingForCertificate({});
        setSelectedLocationForTrainingCertificate(null);

    };

    const generatePDF = () => {
        generateCertificate({ ...selectedTrainingForCertificate, fullName: staffDetails.fullName }, selectedLocationForTrainingCertificate);
        handleClose();
    };

    const onUploadDocClose = () => {
        setDocOpenUpload(false);
        getHealthRecords(selectedLocation);
    };

    const onMainLocationChange = (loc) => {
        setSelectedLocation(loc.value);
        setSelectedLocForTask(loc.value);
        getStaffDetails(loc.value);
        getLocationRoles(loc.value);
        getUsersTasks({ type: 'assigned', limit: 5 }, loc.value);
        setTimeout(() => {
            getPPE(ppeType);
        }, 10);
    };

    const openResendInvite = (e) => {
        e.stopPropagation();
        setResendModalOpen(true);
    };

    const closeResendModal = () => {
        setResendModalOpen(false);
    };

    return (
        <StaffViewContainer>
            <StaffViewHeaderRow>
                {staffLoading ?
                    <Col lg={12} style={{ minHeight: '30px' }} className='text-center'>
                        <Spinner animation="border" variant="success" />
                    </Col> :
                    <Row className="px-0 mx-0 w-100">
                        <div className="align-items-center d-flex justify-content-center">
                            <NameBlockElement fontSize='2.5rem' height='5rem' firstName={staffDetails.firstName} lastName={staffDetails.lastName} />
                        </div>
                        <Col className="px-0">
                            <div className="d-flex justify-content-between">
                                <NameTitle>{staffDetails.fullName}</NameTitle>
                                {staffDetails.joinCode &&
                                    <StatusBadge>
                                        <div className="badge yellow mb-2">
                                            <div className="text">
                                                Invite Pending
                                                <TransparentButton onClick={openResendInvite} className='ml-1'>
                                                    <img src={refreshIcon} alt='refresh icon' />
                                                </TransparentButton>
                                            </div>
                                        </div>
                                    </StatusBadge>
                                }
                            </div>
                            <Row className="align-items-center mx-0">
                                <InputWithLabel className="pr-2">
                                    <LabelText>Location:</LabelText>
                                    <Select
                                        isDisabled={editStaffOpen}
                                        className="w-200px"
                                        options={staffDetails.location_details}
                                        value={staffDetails.location_details.find((lo) => lo._id === selectedLocation)}
                                        menuPosition="fixed"
                                        onChange={onMainLocationChange}
                                        styles={DropDownStyle}
                                    />
                                    <Pipe>|</Pipe>
                                </InputWithLabel>
                                <InputWithLabel className="px-2">
                                    <LabelText>Position:</LabelText>
                                    {!editStaffOpen ?
                                        staffDetails.adminLocations?.includes(selectedLocation) ? 'Administrator' : 'Non Administrator' :
                                        <Select
                                            isDisabled={dataToUpdate.disabled}
                                            className="w-200px"
                                            options={positions}
                                            value={positions.find(po => (po.value === dataToUpdate.temp_position))}
                                            menuPosition="fixed"
                                            onChange={(e) => onDropDownChange('position', e)}
                                            styles={DropDownStyle}
                                        />
                                    }
                                    &nbsp;&nbsp;
                                    <Pipe>|</Pipe>
                                </InputWithLabel>
                                <InputWithLabel className="px-2">
                                    <LabelText>Role:</LabelText>
                                    {!editStaffOpen ?
                                        staffDetails.role?.name || '- - '
                                        :
                                        <Select
                                            className="w-200px"
                                            options={roles}
                                            value={roles.find((ro) => dataToUpdate.roles.includes(ro.value))}
                                            menuPosition="fixed"
                                            onChange={(e) => onDropDownChange('role', e)}
                                            styles={DropDownStyle}
                                        />
                                    }  &nbsp;&nbsp;
                                    <Pipe>|</Pipe>

                                </InputWithLabel>
                                <InputWithLabel className="px-2">
                                    <LabelText>Email:</LabelText>{staffDetails.email}&nbsp;&nbsp;<Pipe>|</Pipe>
                                </InputWithLabel>
                                <InputWithLabel className="ps-2">
                                    <LabelText>Phone:</LabelText>{(staffDetails.phone) ? `+${staffDetails.countryCode}  ${staffDetails.phone}` : '--'} &nbsp;&nbsp;
                                </InputWithLabel>
                                {!user && !editStaffOpen &&
                                    <button className='btn cursor-pointer' onClick={editStaffDetails}>
                                        <img className="px-2" src={pencilGreen} alt="Edit Arrow" />
                                    </button>
                                }
                                <div className='row justify-content-end col pr-0'>
                                    <IconButton className='mx-1' onClick={() => { history.goBack() }}>
                                        {'<< Back'}
                                    </IconButton>
                                    <IconButton className='mx-1' onClick={() => setEmergencyContactOpen(true)}>
                                        Emergency contact
                                    </IconButton>
                                </div>
                                {editStaffOpen ?
                                    <Col lg={12} className='m-2 d-flex align-items-center'>
                                        <Button onClick={() => setEditStaffOpen(false)} variant='light' className="px-5 mx-2">Cancel</Button>
                                        <Button onClick={saveUserRoleAndPosition} variant='success' className="px-5 mx-2">Save</Button>
                                        {dataToUpdate.disabled ?
                                            <b className='mb-0 ml-5 text-danger'>
                                                {staffDetails.firstName} being the only admin for this location, won't be able to change the location and position.
                                            </b>
                                            : null
                                        }
                                    </Col>
                                    :
                                    null
                                }
                            </Row>
                        </Col>
                    </Row>
                }
            </StaffViewHeaderRow>
            <DetailContainer className="mt-5">
                <SectionBlock>
                    <SectionHeading>Health Records Management</SectionHeading>
                    {recordLoading ?
                        <Col lg={12} style={{ minHeight: '30px' }} className='text-center'>
                            <Spinner animation="border" variant="success" />
                        </Col>
                        :
                        <Table>
                            <thead>
                                <tr>
                                    <th className='w-25 pl-4'>Type</th>
                                    <th className='w-25'>Name</th>
                                    <th className='w-25'>Date Added</th>
                                    <th className='w-25'>Uploaded By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {recordList.length ?
                                    recordList.map((record) =>
                                        <tr key={record._id}>
                                            <td className='w-25 pl-4'>{record.healthDocType || 'Other'}</td>
                                            <td className='w-25'>{record.name}</td>
                                            <td className='w-25'>{moment(record.completedAt).format('MM/DD/YYYY')}</td>
                                            <td className='w-25'>{record.completedBy.fullName}</td>
                                        </tr>
                                    ) :
                                    <tr>
                                        <td colSpan='4'>
                                            <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="info">
                                                <h3 className='w-100 mb-0 text-center'>
                                                    No health record uploaded yet
                                                </h3>
                                            </Alert>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    }
                    <Col className='text-center dash-border py-3'>
                        <span onClick={() => setDocOpenUpload(true)} className="cursor-pointer">
                            <img src={uploadDocGreen} alt="" />
                            <SubText className="text-success mb-0">Click to upload new document</SubText>
                            <SubText className="text-secondary mb-0">DOC, PDF or TXT</SubText>
                        </span>
                    </Col>
                </SectionBlock>
            </DetailContainer>
            <DetailContainer className="mt-5">
                <SectionBlock>
                    <SectionHeading>Tasks </SectionHeading>
                    {taskLoading ?
                        <Col lg={12} style={{ minHeight: '30px' }} className='text-center'>
                            <Spinner animation="border" variant="success" />
                        </Col>
                        :
                        <>
                            <NavigationTabSection>
                                <NavTab className={taskFilter.type === 'assigned' ? 'active' : ''} onClick={() => getTaskOnChanges('assigned', 5)} lg='6' sm='12' md='6' title="Assigned" >
                                    Assigned
                                </NavTab>
                                <NavTab className={taskFilter.type === 'completed' ? 'active' : ''} lg='6' sm='12' md='6' title="Completed" onClick={() => getTaskOnChanges('completed', 5)}>
                                    Completed
                                </NavTab>
                            </NavigationTabSection>
                            <Row className="mx-0 mb-2 justify-content-end">
                                <InputWithLabel className="pr-2">
                                    <LabelText>Location:</LabelText>
                                    <Select
                                        className="w-200px"
                                        options={staffDetails.location_details}
                                        menuPosition="fixed"
                                        onChange={(e) => getUsersTasks(taskFilter, e.value)}
                                        value={staffDetails.location_details?.find((lo) => lo._id === selectedLocForTask)}
                                        styles={DropDownStyle}
                                    ></Select>
                                </InputWithLabel>
                            </Row>
                            {taskFilter.type === 'assigned' ?
                                <SectionBlock>
                                    <InfoCard>
                                        Currently Assigned Tasks
                                    </InfoCard>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th className='w-10 pl-4'>Number</th>
                                                <th className='w-35'>Task Name</th>
                                                <th className='w-25'>Frequency</th>
                                                <th className='w-25'>Due Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {taskList.length ?
                                                taskList.map((task, index) =>
                                                    <tr key={task._id}>
                                                        <td className='w-10 pl-4'>
                                                            {index + 1}
                                                        </td>
                                                        <td className='w-35'>
                                                            {task.name}
                                                        </td>
                                                        <td className='w-25'>
                                                            {task.frequencyName}
                                                        </td>
                                                        <td className='w-25'>
                                                            {moment(task.due_date).format('MM/DD/YYYY')}
                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td colSpan='4'>
                                                        <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="info">
                                                            <h3 className='w-100 mb-0 text-center'>
                                                                No task {taskFilter.type} for this user
                                                            </h3>
                                                        </Alert>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </SectionBlock>
                                :
                                <SectionBlock>
                                    <InfoCard>
                                        Completed Tasks
                                    </InfoCard>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th className='w-20 pl-4'>Number</th>
                                                <th className='w-50'>Task Name</th>
                                                <th className='w-25'>Completed Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {taskList.length ?
                                                taskList.map((task, index) =>
                                                    <tr key={task._id}>
                                                        <td className='w-20 pl-4'>
                                                            {index + 1}
                                                        </td>
                                                        <td className='w-50'>
                                                            {task.name}
                                                        </td>
                                                        <td className='w-25'>
                                                            {moment(task.completedAt).format('MM/DD/YYYY hh:mm A')}
                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td colSpan='4'>
                                                        <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="info">
                                                            <h3 className='w-100 mb-0 text-center'>
                                                                No task {taskFilter.type} for this user
                                                            </h3>
                                                        </Alert>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </SectionBlock>}
                        </>
                    }
                </SectionBlock>
            </DetailContainer>
            <DetailContainer className="mt-5">
                <SectionBlock>
                    <SectionHeading>Trainings</SectionHeading>
                    <>
                        <NavigationTabSection className='mb-2'>
                            <NavTab className={trainingType === 'in-progress' ? 'active' : ''} onClick={() => getTrainingData('in-progress',)} lg='6' sm='12' md='6' title="Assigned" >
                                In Progress
                            </NavTab>
                            <NavTab className={trainingType === 'completed' ? 'active' : ''} lg='6' sm='12' md='6' title="Completed" onClick={() => getTrainingData('completed')}>
                                Completed
                            </NavTab>
                        </NavigationTabSection>
                        {trainingLoading ?
                            <Col lg={12} style={{ minHeight: '30px' }} className='text-center'>
                                <Spinner animation="border" variant="success" />
                            </Col>
                            :
                            trainingType === 'completed' ?
                                <Row>
                                    {(trainingList && trainingList.length) ? trainingList.map((training) => (
                                        <Col xs={12} md={4} lg={4} key={training._id} className="my-3">
                                            <CustomCard onClick={() => {
                                                setSelectedTrainingForCertificate(training);
                                                setSelectedLocationModal(true); setSelectedLocationForTrainingCertificate(null)
                                            }}>
                                                <CardBody>
                                                    <IconContainer className="justify-content-start align-items-center">
                                                        <ImageContainer
                                                            width={50}
                                                            height={50}
                                                            alt="50x50"
                                                            src={certificate}
                                                        />
                                                        <Figure.Caption>
                                                            <h5 >{training.name}</h5>
                                                            <h5>Date: {moment(training.completedAt).format('MM/DD/YYYY')}</h5>
                                                        </Figure.Caption>
                                                    </IconContainer>
                                                </CardBody>
                                            </CustomCard>
                                        </Col>
                                    )) :
                                        <Col xs={12} md={12} lg={12}>
                                            <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="info">
                                                <h3 className='w-100 mb-0 text-center'>
                                                    User has not completed training yet
                                                </h3>
                                            </Alert>
                                        </Col>
                                    }
                                </Row>
                                :
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className='w-20 pl-4'>Actual Training</th>
                                            <th className='w-30'>Training Progress</th>
                                            <th className='w-50 text-center'>Due Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {trainingList.length ?
                                            trainingList.map((training) =>
                                                <tr key={training.eTag}>
                                                    <td className='w-20 pl-4'>{training.name}</td>
                                                    <td className='w-30'>
                                                        <HorizontalProgressBar total={training.duration} completed={training.currentTime || 0} />
                                                    </td>
                                                    <td className='w-50 text-center'>{moment(training.due_date).format('MM/DD/YYYY')}</td>
                                                </tr>
                                            )
                                            : <tr>
                                                <td colSpan={3}>
                                                    <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="info">
                                                        <h3 className='w-100 mb-0 text-center'>
                                                            {
                                                                trainingType === 'in-progress' ? 'User does not have any training in progress.' : trainingType === 'not-started' ? 'User has pending training yet' : 'User has not completed training yet'
                                                            }
                                                        </h3>
                                                    </Alert>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                        }
                    </>
                </SectionBlock>
            </DetailContainer>
            <DetailContainer className="mt-5">
                <SectionBlock>
                    <SectionHeading>PPE</SectionHeading>
                    {ppeLoading ?
                        <Col lg={12} style={{ minHeight: '30px' }} className='text-center'>
                            <Spinner animation="border" variant="success" />
                        </Col>
                        :
                        <Row>
                            {(ppeList && ppeList.length) ?
                                ppeList.map((equipment) => (
                                    <Col xs={12} md={4} lg={4} key={equipment._id} className="my-3">
                                        <CustomCard>
                                            <CardBody>
                                                <IconContainer className="justify-content-start align-items-center">
                                                    <ImageContainer
                                                        width={50}
                                                        height={50}
                                                        alt="50x50"
                                                        src={icons[equipment.id] || customSafetyEquipment}
                                                    />
                                                    <Figure.Caption>
                                                        <h4 className="text-dark">{equipment.name}</h4>
                                                        <p>
                                                            PPE Acceptance at  {moment(staffDetails.createdAt).format('HH:mm MM/DD/YY')}
                                                        </p>
                                                    </Figure.Caption>
                                                </IconContainer>
                                            </CardBody>
                                        </CustomCard>
                                    </Col>
                                ))
                                :
                                <Col xs={12} md={12} lg={12} >
                                    <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="info">
                                        <h3 className='w-100 mb-0 text-center'>
                                            No PPE Selected yet
                                        </h3>
                                    </Alert>
                                </Col>
                            }
                        </Row>
                    }
                </SectionBlock>
            </DetailContainer>
            {
                docUploadOpen &&
                <HealthRecordForm close={setDocOpenUpload} afterSave={onUploadDocClose} show={docUploadOpen} staffDetails={staffDetails} currentUser={currentUser} healthRecordType={healthRecordType} />
            }
            {
                emergencyContactOpen &&
                <EmergencyContact show={emergencyContactOpen} close={setEmergencyContactOpen} user={staffDetails} />
            }
            {
                resendModalOpen &&
                <ResendInviteModal resendModalOpen={resendModalOpen} getUsers={() => getStaffDetails(selectedLocation)} closeResendModal={closeResendModal} userId={userId} />
            }
            <Modal
                show={selectLocationModal}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                animation={true}
                contentClassName='trainingMaterialsInfoModel'
            >
                <Modal.Header>
                    <Modal.Title>
                        Select Location
                    </Modal.Title>
                </Modal.Header>
                <ModalBody className='m1-3 mx-4'>
                    <LabelText>
                        Choose the location which you want to view on the training certificate.
                    </LabelText>
                    <Select
                        onChange={(e) => setSelectedLocationForTrainingCertificate(e.label)}
                        options={locationList}
                        styles={DropDownStyle}
                    />
                </ModalBody>
                <ModalFooter>
                    <button className='btn btn-danger' onClick={handleClose}>
                        Close
                    </button>
                    <button className='btn btn-success' onClick={generatePDF}>
                        View Certificate
                    </button>
                </ModalFooter>
            </Modal>
        </StaffViewContainer >
    )
}

export default StaffDetails;